import { Button, Form, Input, Select } from "antd";
import React, { useEffect, useState } from "react";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm, Controller } from "react-hook-form";
import { HeaderForm } from "./HeaderForm";
import { BackendApiUrl } from "@/functions/BackendApiUrl";
import useSWR from "swr";
import { useSwrFetcherWithAccessToken } from "@/functions/useSwrFetcherWithAccessToken";
import { SelectOption, Dropdown } from "@/interfaces/SelectOption";

const FormSchema = z.object({
  name: z
    .string({
      required_error: "Nama tidak boleh kosong",
    })
    .nonempty({
      message: "Nama tidak boleh kosong",
    })
    .max(255, {
      message: "Nama harus kurang dari 255 karakter",
    })
    .refine((value) => !/^\s*$/.test(value), {
      message: "Tidak boleh hanya berisi spasi",
    }),
  noKtp: z
    .string({
      required_error: "Nomor KTP tidak boleh kosong",
    })
    .refine((value) => /^\d{16}$/.test(value), {
      message: "Nomor KTP harus 16 digit dan angka",
    }),
  npwpStatus: z.string({
    required_error: "NPWP tidak boleh kosong",
  }),
  noNpwp: z
    .string({
      required_error: "Nomor NPWP tidak boleh kosong",
    })
    .refine((value) => /^\d{15}$/.test(value), {
      message: "Nomor NPWP harus 15 digit dan angka",
    }),
  gender: z
    .string({
      required_error: "Jenis kelamin tidak boleh kosong",
    })
    .nonempty({
      message: "Jenis kelamin tidak boleh kosong",
    }),
  statusBpjsKesehatan: z
    .string({
      required_error: "BPJS kesehatan tidak boleh kosong",
    })
    .nonempty({
      message: "BPJS kesehatan tidak boleh kosong",
    }),
  statusBpjsKetenagakerjaan: z
    .string({
      required_error: "BPJS Ketenagakerjaan tidak boleh kosong",
    })
    .nonempty({
      message: "BPJS Ketenagakerjaan tidak boleh kosong",
    }),
  education: z
    .string({
      required_error: "Jenjang pendidikan tidak boleh kosong",
    })
    .nonempty({
      message: "Jenjang pendidikan tidak boleh kosong",
    })
    .max(255, {
      message: "Jenjang pendidikan harus kurang dari 255 karakter",
    })
    .refine((value) => !/^\s*$/.test(value), {
      message: "Tidak boleh hanya berisi spasi",
    }),
  title: z
    .string({
      required_error: "Nama instansi pendidikan tidak boleh kosong",
    })
    .nonempty({
      message: "Nama instansi pendidikan tidak boleh kosong",
    })
    .max(255, {
      message: "Nama instansi pendidikan harus kurang dari 255 karakter",
    })
    .refine((value) => !/^\s*$/.test(value), {
      message: "Tidak boleh hanya berisi spasi",
    }),
  tahunMasukPendidikan: z
    .string({
      required_error: "Tahun masuk pendidikan tidak boleh kosong",
    })
    .refine((value) => /^[0-9]*$/.test(value), {
      message: "Tahun Masuk pendidikan harus berupa angka",
    }),
  tahunKeluarPendidikan: z
    .string({
      required_error: "Tahun keluar pendidikan tidak boleh kosong",
    })
    .refine((value) => /^[0-9]*$/.test(value), {
      message: "Tahun Keluar pendidikan harus berupa angka",
    }),
  gpa: z
    .string({
      required_error: " IPK/Rata-rata nilai terakhir tidak boleh kosong",
    })
    .nonempty({
      message: " IPK/Rata-rata nilai terakhir tidak boleh kosong",
    })
    .refine((value) => /^[0-9.,]*$/.test(value), {
      message: " IPK/Rata-rata nilai terakhir harus berupa angka ",
    })
    .refine(
      (value) => {
        const numericValue = parseFloat(value);
        return numericValue >= 1 && numericValue <= 100;
      },
      {
        message:
          " IPK/Rata-rata nilai terakhir harus berada dalam rentang 1 sampai 100",
      }
    ),
  bloodType: z
    .string({
      required_error: "Golongan darah tidak boleh kosong",
    })
    .nonempty({
      message: "Golongan darah tidak boleh kosong",
    })
    .refine((value) => !/^\s*$/.test(value), {
      message: "Tidak boleh hanya berisi spasi",
    }),
  tempatLahir: z
    .string({
      required_error: "Tempat lahir tidak boleh kosong",
    })
    .nonempty({
      message: "Tempat lahir tidak boleh kosong",
    })
    .max(255, {
      message: "Tempat lahir harus kurang dari 255 karakter",
    })
    .refine((value) => !/^\s*$/.test(value), {
      message: "Tidak boleh hanya berisi spasi",
    }),
  tglLahir: z
    .string({
      required_error: "Tanggal lahir tidak boleh kosong",
    })
    .nonempty({
      message: "Tanggal lahir tidak boleh kosong",
    })
    .refine(
      (value) => {
        const inputDate = new Date(value);
        const today = new Date();
        return inputDate <= today;
      },
      {
        message: "Tanggal tidak boleh lebih besar dari tanggal hari ini",
      }
    ),
  alamatKtp: z
    .string({
      required_error: "Alamat (sesuai KTP) tidak boleh kosong",
    })
    .nonempty({
      message: "Alamat (sesuai KTP) tidak boleh kosong",
    })
    .max(255, {
      message: "Alamat (sesuai KTP) harus kurang dari 255 karakter",
    })
    .refine((value) => !/^\s*$/.test(value), {
      message: "Tidak boleh hanya berisi spasi",
    }),
  alamatDomisili: z
    .string({
      required_error: "Alamat saat ini tidak boleh kosong",
    })
    .nonempty({
      message: "Alamat (saat ini) tidak boleh kosong",
    })
    .max(255, {
      message: "Alamat (saat ini) harus kurang dari 255 karakter",
    })
    .refine((value) => !/^\s*$/.test(value), {
      message: "Tidak boleh hanya berisi spasi",
    }),
  noHp: z
    .string({
      required_error: "Nomor HP tidak boleh kosong",
    })
    .nonempty({
      message: "Nomor HP tidak boleh kosong",
    })
    .startsWith("08", {
      message: "Nomor HP harus diawali 08",
    })
    .refine((value) => /^[0-9]*$/.test(value), {
      message: "Nomor HP harus berupa angka",
    }),
  noTel: z
    .string({
      required_error: "Nomor Telephone tidak boleh kosong",
    })
    .max(30, {
      message: "Nomor Telephone rumah harus kurang dari 30",
    })
    .refine((value) => /^[0-9]*$/.test(value), {
      message: "Nomor Telephone rumah harus berupa angka",
    })
    .optional(),
  namaKontakDarurat: z
    .string({
      required_error: "Nama kontak darurat tidak boleh kosong",
    })
    .nonempty({
      message: "Nama kontak darurat tidak boleh kosong",
    })
    .max(255, {
      message: "Nama kontak darurat harus kurang dari 255 karakter",
    })
    .refine((value) => !/^\s*$/.test(value), {
      message: "Tidak boleh hanya berisi spasi",
    }),
  hubunganKontakDarurat: z
    .string({
      required_error: "Hubungan kontak darurat tidak boleh kosong",
    })
    .nonempty({
      message: "Hubungan kontak darurat tidak boleh kosong",
    })
    .max(255, {
      message: "Hubungan kontak darurat harus kurang dari 255 karakter",
    })
    .refine((value) => !/^\s*$/.test(value), {
      message: "Tidak boleh hanya berisi spasi",
    }),
  noKontakDarurat: z
    .string({
      required_error: "Nomor kontak darurat tidak boleh kosong",
    })
    .nonempty({
      message: "Nomor kontak darurat tidak boleh kosong",
    })
    .startsWith("08", {
      message: "Nomor kontak darurat harus diawali 08",
    })
    .refine((value) => /^[0-9]*$/.test(value), {
      message: "Nomor HP harus berupa angka",
    }),
  alamatEmail: z
    .string({
      required_error: "Alamat email tidak boleh kosong",
    })
    .nonempty({
      message: "Alamat email tidak boleh kosong",
    })
    .max(255, {
      message: "Alamat email harus kurang dari 255 karakter",
    })
    .email({
      message: "Alamat email tidak valid",
    }),
  agama: z
    .number({
      required_error: "Agama tidak boleh kosong",
    })
    .min(0, {
      message: "Agama tidak boleh kosong",
    }),
  noSaudara: z
    .number({
      required_error: "Anak ke-x  tidak boleh kosong",
      invalid_type_error: "Anak ke-x Tidak boleh kosong",
    })
    .min(1, {
      message: "Anak ke-x harus lebih dari 0",
    }),
  jumlahSaudara: z
    .number({
      required_error: "Jumlah saudara tidak boleh kosong",
      invalid_type_error: "Jumlah saudara tidak boleh kosong",
    })
    .min(1, {
      message: "Jumlah saudara harus lebih dari 0",
    }),
  statusPernikahan: z
    .string({
      required_error: "Status pernikahan tidak boleh kosong",
    })
    .nonempty({
      message: "Status pernikahan tidak boleh kosong",
    }),
  jumlahAnak: z
    .string({
      required_error: "Jumlah anak tidak boleh kosong",
    })
    .max(255, {
      message: "Jumlah anak harus kurang dari 255 karakter",
    })
    .refine((value) => /^[0-9]*$/.test(value), {
      message: "Jumlah anak harus berupa angka",
    })
    .optional(),
  kewarganegaraan: z
    .string({
      required_error: "Kewarganegaraan tidak boleh kosong",
    })
    .nonempty({
      message: "Kewarganegaraan tidak boleh kosong",
    }),
  medSosStatus: z
    .string({
      required_error: "Media sosial tidak boleh kosong",
    })
    .nonempty({
      message: "Media sosial tidak boleh kosong",
    }),
  medSos: z
    .string({
      required_error: "Media sosial tidak boleh kosong",
    })
    .refine(
      (value) => {
        if (value === "5") {
          return false;
        }
        return true;
      },
      {
        message: "Pilih opsi yang valid",
      }
    ),
  medSosUsername: z
    .string({
      required_error: "Nama Akun tidak boleh kosong",
    })
    .max(255, {
      message: "Nama Akun harus kurang dari 255 karakter",
    })
    .refine((value) => !/^\s*$/.test(value), {
      message: "Tidak boleh kosong",
    }),
  riwayatPenyakit: z
    .string()
    .max(255, {
      message: "Riwayat penyakit harus kurang dari 255 karakter",
    })
    .optional(),
});

export type personalFormDataType = z.infer<typeof FormSchema>;

const PersonalDataForm: React.FC<{
  onSubmit: (data: personalFormDataType) => void;
  personalDataForm?: personalFormDataType;
  onNextPage: () => void;
  onPrevPage: () => void;
}> = ({ onSubmit, personalDataForm, onNextPage, onPrevPage }) => {
  const {
    control,
    formState: { errors },
    handleSubmit,
    setValue,
    watch,
  } = useForm<personalFormDataType>({
    resolver: zodResolver(FormSchema),
  });

  const noSaudaraValue = watch("noSaudara");
  const jumlahSaudaraValue = watch("jumlahSaudara");

  useEffect(() => {
    if (personalDataForm) {
      setValue("name", personalDataForm.name);
      setValue("noKtp", personalDataForm.noKtp);
      setValue("npwpStatus", personalDataForm.npwpStatus);
      setValue("noNpwp", personalDataForm.noNpwp);
      setValue("gender", personalDataForm.gender);
      setValue("statusBpjsKesehatan", personalDataForm.statusBpjsKesehatan);
      setValue(
        "statusBpjsKetenagakerjaan",
        personalDataForm.statusBpjsKetenagakerjaan
      );
      setValue("education", personalDataForm.education);
      setValue("title", personalDataForm.title);
      setValue("tahunMasukPendidikan", personalDataForm.tahunMasukPendidikan);
      setValue("tahunKeluarPendidikan", personalDataForm.tahunKeluarPendidikan);
      setValue("gpa", personalDataForm.gpa);
      setValue("bloodType", personalDataForm.bloodType);
      setValue("tempatLahir", personalDataForm.tempatLahir);
      setValue("tglLahir", personalDataForm.tglLahir);
      setValue("alamatKtp", personalDataForm.alamatKtp);
      setValue("alamatDomisili", personalDataForm.alamatDomisili);
      setValue("noHp", personalDataForm.noHp);
      setValue("noTel", personalDataForm.noTel);
      setValue("namaKontakDarurat", personalDataForm.namaKontakDarurat);
      setValue("hubunganKontakDarurat", personalDataForm.hubunganKontakDarurat);
      setValue("noKontakDarurat", personalDataForm.noKontakDarurat);
      setValue("alamatEmail", personalDataForm.alamatEmail);
      setValue("agama", personalDataForm.agama);
      setValue("noSaudara", personalDataForm.noSaudara);
      setValue("jumlahSaudara", personalDataForm.jumlahSaudara);
      setValue("statusPernikahan", personalDataForm.statusPernikahan);
      setValue("jumlahAnak", personalDataForm.jumlahAnak);
      setValue("kewarganegaraan", personalDataForm.kewarganegaraan);
      setValue("medSosStatus", personalDataForm.medSosStatus);
      setValue("medSos", personalDataForm.medSos);
      setValue("medSosUsername", personalDataForm.medSosUsername);
      setValue("riwayatPenyakit", personalDataForm.riwayatPenyakit);
    }
  }, [personalDataForm, setValue]);

  console.log(personalDataForm);

  const [firstVisit, setFirstVisit] = useState(true);

  useEffect(() => {
    if (firstVisit) {
      // Jika ini adalah kunjungan pertama, arahkan ke atas halaman
      window.scrollTo(0, 0);

      // Setel status kunjungan pertama menjadi false
      setFirstVisit(false);
    }
  }, [firstVisit]);

  function onHandleFormSubmit(data: personalFormDataType) {
    if (jumlahSaudaraValue >= noSaudaraValue) {
      onSubmit(data);
      onNextPage();
    }
  }

  const swrFetcher = useSwrFetcherWithAccessToken();

  const { data: religionList } = useSWR<SelectOption<number>[]>(
    BackendApiUrl.getReligion,
    swrFetcher
  );

  function religionsList(): Dropdown<number>[] {
    if (!religionList) {
      return [];
    }

    return religionList.map((item) => {
      const row: Dropdown<number> = {
        label: item.name,
        value: item.id,
      };
      return row;
    });
  }

  const { data: degreeList } = useSWR<SelectOption<string>[]>(
    BackendApiUrl.getDegrees,
    swrFetcher
  );

  function degreesList(): Dropdown<string>[] {
    if (!degreeList) {
      return [];
    }

    return degreeList.map((item) => {
      const row: Dropdown<string> = {
        label: item.name,
        value: item.name,
      };
      return row;
    });
  }

  const { data: socialMediaList } = useSWR<SelectOption<string>[]>(
    BackendApiUrl.getSocialMedia,
    swrFetcher
  );

  function socialMediasList(): Dropdown<string>[] {
    if (!socialMediaList) {
      return [];
    }

    return socialMediaList.map((item) => {
      const row: Dropdown<string> = {
        label: item.name,
        value: item.name,
      };
      return row;
    });
  }

  useEffect(() => {
    if (watch("npwpStatus") === "1") {
      setValue("noNpwp", "000000000000000");
    } else if (
      watch("npwpStatus") === "2" &&
      watch("noNpwp") === "000000000000000"
    ) {
      setValue("noNpwp", "");
    } else if (
      personalDataForm &&
      watch("npwpStatus") === "2" &&
      watch("noNpwp") !== "000000000000000"
    ) {
      setValue("noNpwp", personalDataForm.noNpwp);
    }
  }, [watch("npwpStatus")]);

  useEffect(() => {
    if (watch("medSosStatus") === "1") {
      setValue("medSosUsername", "-");
    } else if (
      watch("medSosStatus") === "2" &&
      watch("medSosUsername") === "-"
    ) {
      setValue("medSosUsername", "");
    } else if (
      personalDataForm &&
      watch("medSosStatus") === "2" &&
      watch("medSosUsername") !== "-"
    ) {
      setValue("medSosUsername", personalDataForm.medSosUsername);
    }
  }, [watch("medSosStatus")]);

  useEffect(() => {
    if (watch("medSosStatus") === "1") {
      setValue("medSos", "6");
    } else if (watch("medSosStatus") === "2" && watch("medSos") === "6") {
      setValue("medSos", "5");
    } else if (
      personalDataForm &&
      watch("medSosStatus") === "2" &&
      watch("medSos") !== "6"
    ) {
      setValue("medSos", personalDataForm.medSos);
    }
  }, [watch("medSosStatus")]);

  useEffect(() => {
    if(watch("gpa") && watch("gpa").includes(",")){
      setValue("gpa", watch("gpa").replace(",", "."))
    }
  }, [watch("gpa")])

  const currentYear = new Date().getFullYear();
  const years = Array.from({ length: 50 }, (_, index) => ({
    value: `${currentYear - index}`,
    label: `${currentYear - index}`,
  }));

  return (
    <>
      <HeaderForm steps={1} />
      <Form layout="vertical" onFinish={handleSubmit(onHandleFormSubmit)}>
        <div className="font-bold text-xl mb-2 mt-5">A. DATA PRIBADI</div>

        <Form.Item
          name="name"
          label={
            <div className="flex items-center">
              <label className="mr-2 font-medium">
                Nama Lengkap (Sesuai KTP)
              </label>
              <span className="text-red-500">*</span>
            </div>
          }
          validateStatus={errors.name ? "error" : ""}
          help={errors.name ? errors.name.message : ""}
        >
          <Controller
            name="name"
            control={control}
            render={({ field }) => (
              <Input {...field} placeholder="Masukkan nama lengkap anda" />
            )}
          />
        </Form.Item>

        <Form.Item
          name="noKtp"
          label={
            <div className="flex items-center">
              <label className="mr-2 font-medium">Nomor KTP</label>
              <span className="text-red-500">*</span>
            </div>
          }
          validateStatus={errors.noKtp ? "error" : ""}
          help={errors.noKtp ? errors.noKtp.message : ""}
        >
          <Controller
            name="noKtp"
            control={control}
            render={({ field }) => (
              <Input {...field} placeholder="Masukkan nomor KTP anda" />
            )}
          />
        </Form.Item>

        <Form.Item
          label={
            <div className="flex items-center">
              <label className="mr-2 font-medium">Nomor NPWP</label>
              <span className="text-red-500">*</span>
            </div>
          }
          validateStatus={
            errors.npwpStatus || (watch("npwpStatus") === "2" && errors.noNpwp)
              ? "error"
              : ""
          }
          help={errors.npwpStatus ? errors.npwpStatus.message : ""}
        >
          <Controller
            name="npwpStatus"
            control={control}
            render={({ field }) => (
              <div className="flex">
                <label className="flex items-center space-x-2">
                  <input
                    type="radio"
                    {...field}
                    checked={field.value === "2"}
                    onChange={() => field.onChange("2")}
                    className="appearance-none border-2 border-[#948A54] rounded-full w-4 h-4 checked:bg-[#948A54] checked:border-[#948A54] inline-flex items-center justify-center"
                  />
                  <span>Ada</span>
                </label>
                <label className="flex items-center space-x-2 ml-5">
                  <input
                    type="radio"
                    {...field}
                    checked={field.value === "1"}
                    onChange={() => field.onChange("1")}
                    className="appearance-none border-2 border-[#948A54] rounded-full w-4 h-4 checked:bg-[#948A54] checked:border-[#948A54] inline-flex items-center justify-center"
                  />
                  <span>Tidak Ada</span>
                </label>
              </div>
            )}
          />

          {watch("npwpStatus") === "2" && (
            <>
              <Controller
                name="noNpwp"
                control={control}
                render={({ field }) => (
                  <Input
                    {...field}
                    className="mt-2"
                    placeholder="Masukkan nomor NPWP anda"
                  />
                )}
              />
              <div>
                <p className="text-red-500">{errors["noNpwp"]?.message}</p>
              </div>
            </>
          )}
        </Form.Item>

        <Form.Item
          name="gender"
          label={
            <div className="flex items-center">
              <label className="mr-2 font-medium">Jenis Kelamin</label>
              <span className="text-red-500">*</span>
            </div>
          }
          validateStatus={errors.gender ? "error" : ""}
          help={errors.gender ? errors.gender.message : ""}
        >
          <div className="flex flex-col">
            <Controller
              name="gender"
              control={control}
              render={({ field }) => (
                <div className="flex">
                  <label className="flex items-center space-x-2">
                    <input
                      type="radio"
                      {...field}
                      checked={field.value === "1"}
                      onChange={() => field.onChange("1")}
                      className="appearance-none border-2 border-[#948A54] rounded-full w-4 h-4 checked:bg-[#948A54] checked:border-[#948A54] inline-flex items-center justify-center"
                    />
                    <span>Pria</span>
                  </label>
                  <label className="flex items-center space-x-2 ml-5">
                    <input
                      type="radio"
                      {...field}
                      checked={field.value === "2"}
                      onChange={() => field.onChange("2")}
                      className="appearance-none border-2 border-[#948A54] rounded-full w-4 h-4 checked:bg-[#948A54] checked:border-[#948A54] inline-flex items-center justify-center"
                    />
                    <span>Wanita</span>
                  </label>
                </div>
              )}
            ></Controller>
          </div>
        </Form.Item>

        <Form.Item
          name="statusBpjsKesehatan"
          label={
            <div className="flex items-center">
              <label className="mr-2 font-medium">
                Memiliki BPJS Kesehatan?
              </label>
              <span className="text-red-500">*</span>
            </div>
          }
          validateStatus={errors.statusBpjsKesehatan ? "error" : ""}
          help={
            errors.statusBpjsKesehatan ? errors.statusBpjsKesehatan.message : ""
          }
        >
          <div className="flex flex-col">
            <Controller
              name="statusBpjsKesehatan"
              control={control}
              render={({ field }) => (
                <div className="flex">
                  <label className="flex items-center space-x-2">
                    <input
                      type="radio"
                      {...field}
                      checked={field.value === "1"}
                      onChange={() => field.onChange("1")}
                      className="appearance-none border-2 border-[#948A54] rounded-full w-4 h-4 checked:bg-[#948A54] checked:border-[#948A54] inline-flex items-center justify-center"
                    />
                    <span>Iya</span>
                  </label>
                  <label className="flex items-center space-x-2 ml-5">
                    <input
                      type="radio"
                      {...field}
                      checked={field.value === "2"}
                      onChange={() => field.onChange("2")}
                      className="appearance-none border-2 border-[#948A54] rounded-full w-4 h-4 checked:bg-[#948A54] checked:border-[#948A54] inline-flex items-center justify-center"
                    />
                    <span>Tidak</span>
                  </label>
                </div>
              )}
            ></Controller>
          </div>
        </Form.Item>

        <Form.Item
          name="statusBpjsKetenagakerjaan"
          label={
            <div className="flex items-center">
              <label className="mr-2 font-medium">
                Memiliki BPJS Ketenagakerjaan?
              </label>
              <span className="text-red-500">*</span>
            </div>
          }
          validateStatus={errors.statusBpjsKetenagakerjaan ? "error" : ""}
          help={
            errors.statusBpjsKetenagakerjaan
              ? errors.statusBpjsKetenagakerjaan.message
              : ""
          }
        >
          <div className="flex flex-col">
            <Controller
              name="statusBpjsKetenagakerjaan"
              control={control}
              render={({ field }) => (
                <div className="flex">
                  <label className="flex items-center space-x-2">
                    <input
                      type="radio"
                      {...field}
                      checked={field.value === "1"}
                      onChange={() => field.onChange("1")}
                      className="appearance-none border-2 border-[#948A54] rounded-full w-4 h-4 checked:bg-[#948A54] checked:border-[#948A54] inline-flex items-center justify-center"
                    />
                    <span>Iya</span>
                  </label>
                  <label className="flex items-center space-x-2 ml-5">
                    <input
                      type="radio"
                      {...field}
                      checked={field.value === "2"}
                      onChange={() => field.onChange("2")}
                      className="appearance-none border-2 border-[#948A54] rounded-full w-4 h-4 checked:bg-[#948A54] checked:border-[#948A54] inline-flex items-center justify-center"
                    />
                    <span>Tidak</span>
                  </label>
                </div>
              )}
            ></Controller>
          </div>
        </Form.Item>

        <div className="flex">
          <div className="w-2/4 pr-2">
            <Form.Item
              name="education"
              label={
                <div className="flex items-center">
                  <label className="mr-2 font-medium">
                    Pendidikan Terakhir
                  </label>
                  <span className="text-red-500">*</span>
                </div>
              }
              validateStatus={errors.education ? "error" : ""}
              help={errors.education ? errors.education.message : ""}
            >
              <Controller
                name="education"
                control={control}
                render={({ field }) => (
                  <Select
                    placeholder="Pilih jenjang pendidikan terakhir"
                    options={degreesList()}
                    {...field}
                  ></Select>
                )}
              />
            </Form.Item>
          </div>
          <div className="w-3/5 pl-2">
            <Form.Item
              name="title"
              label={<div className="flex items-center"></div>}
              validateStatus={errors.title ? "error" : ""}
              help={errors.title ? errors.title.message : ""}
            >
              <Controller
                name="title"
                control={control}
                render={({ field }) => (
                  <Input
                    {...field}
                    placeholder="Masukkan nama instansi pendidikan terakhir"
                  />
                )}
              />
            </Form.Item>
          </div>
        </div>

        <Form.Item
          name="tahunMasukPendidikan"
          label={
            <div className="flex items-center">
              <label className="mr-2 font-medium">
                Tahun Masuk Pendidikan Terakhir
              </label>
              <span className="text-red-500">*</span>
            </div>
          }
          validateStatus={errors.tahunMasukPendidikan ? "error" : ""}
          help={
            errors.tahunMasukPendidikan
              ? errors.tahunMasukPendidikan.message
              : ""
          }
        >
          <Controller
            name="tahunMasukPendidikan"
            control={control}
            render={({ field }) => (
              <Select
                {...field}
                showSearch
                placeholder="Pilih tahun masuk pendidikan terakhir"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  (option?.label ?? "").includes(input)
                }
                filterSort={(optionA, optionB) =>
                  (optionA?.label ?? "")
                    .toLowerCase()
                    .localeCompare((optionB?.label ?? "").toLowerCase())
                }
                options={years}
              />
            )}
          />
        </Form.Item>

        <Form.Item
          name="tahunLulusPendidikan"
          label={
            <div className="flex items-center">
              <label className="mr-2 font-medium">
                Tahun Lulus Pendidikan Terakhir
              </label>
              <span className="text-red-500">*</span>
            </div>
          }
          validateStatus={errors.tahunKeluarPendidikan ? "error" : ""}
          help={
            errors.tahunKeluarPendidikan
              ? errors.tahunKeluarPendidikan.message
              : ""
          }
        >
          <Controller
            name="tahunKeluarPendidikan"
            control={control}
            render={({ field }) => (
              <Select
                {...field}
                showSearch
                placeholder="Pilih tahun keluar pendidikan terakhir"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  (option?.label ?? "").includes(input)
                }
                filterSort={(optionA, optionB) =>
                  (optionA?.label ?? "")
                    .toLowerCase()
                    .localeCompare((optionB?.label ?? "").toLowerCase())
                }
                options={years}
              />
            )}
          />
        </Form.Item>

        <Form.Item
          name="gpa"
          label={
            <div className="flex items-center">
              <label className="mr-2 font-medium">
                IPK/Rata-Rata Nilai Terakhir
              </label>
              <span className="text-red-500">*</span>
            </div>
          }
          validateStatus={errors.gpa ? "error" : ""}
          help={errors.gpa ? errors.gpa.message : ""}
        >
          <Controller
            name="gpa"
            control={control}
            render={({ field }) => (
              <Input
                {...field}
                placeholder="Masukkan IPK/Rata-Rata nilai terakhir"
              />
            )}
          />
        </Form.Item>

        <Form.Item
          name="bloodType"
          label={
            <div className="flex items-center">
              <label className="mr-2 font-medium">Golongan Darah</label>
              <span className="text-red-500">*</span>
            </div>
          }
          validateStatus={errors.bloodType ? "error" : ""}
          help={errors.bloodType ? errors.bloodType.message : ""}
        >
          <Controller
            name="bloodType"
            control={control}
            render={({ field }) => (
              <Input {...field} placeholder="Masukkan golongan darah anda" />
            )}
          />
        </Form.Item>

        <Form.Item
          name="tempatLahir"
          label={
            <div className="flex items-center">
              <label className="mr-2 font-medium">Tempat Lahir</label>
              <span className="text-red-500">*</span>
            </div>
          }
          validateStatus={errors.tempatLahir ? "error" : ""}
          help={errors.tempatLahir ? errors.tempatLahir.message : ""}
        >
          <Controller
            name="tempatLahir"
            control={control}
            render={({ field }) => (
              <Input {...field} placeholder="Masukkan tempat lahir anda" />
            )}
          />
        </Form.Item>

        <Form.Item
          name="tglLahir"
          label={
            <div className="flex items-center">
              <label className="mr-2 font-medium">Tanggal Lahir</label>
              <span className="text-red-500">*</span>
            </div>
          }
          validateStatus={errors.tglLahir ? "error" : ""}
          help={errors.tglLahir ? errors.tglLahir.message : ""}
        >
          <Controller
            name="tglLahir"
            control={control}
            render={({ field }) => (
              <Input
                type="date"
                {...field}
                placeholder="Pilih tanggal lahir anda"
                pattern="\d{2}-\d{2}-\d{4}"
              />
            )}
          />
        </Form.Item>

        <Form.Item
          name="alamatKtp"
          label={
            <div className="flex items-center">
              <label className="mr-2 font-medium">Alamat (Sesuai KTP)</label>
              <span className="text-red-500">*</span>
            </div>
          }
          validateStatus={errors.alamatKtp ? "error" : ""}
          help={errors.alamatKtp ? errors.alamatKtp.message : ""}
        >
          <Controller
            name="alamatKtp"
            control={control}
            render={({ field }) => (
              <Input
                {...field}
                placeholder="Masukkan alamat yang tertera di KTP anda"
              />
            )}
          />
        </Form.Item>

        <Form.Item
          name="alamatDomisili"
          label={
            <div className="flex items-center">
              <label className="mr-2 font-medium">
                Alamat (Tempat Tinggal Saat Ini)
              </label>
              <span className="text-red-500">*</span>
            </div>
          }
          validateStatus={errors.alamatDomisili ? "error" : ""}
          help={errors.alamatDomisili ? errors.alamatDomisili.message : ""}
        >
          <Controller
            name="alamatDomisili"
            control={control}
            render={({ field }) => (
              <Input
                {...field}
                placeholder="Masukkan alamat tempat tinggal anda sekarang"
              />
            )}
          />
        </Form.Item>

        <Form.Item
          name="noHp"
          label={
            <div className="flex items-center">
              <label className="mr-2 font-medium">Nomor Telephone (HP)</label>
              <span className="text-red-500">*</span>
            </div>
          }
          validateStatus={errors.noHp ? "error" : ""}
          help={errors.noHp ? errors.noHp.message : ""}
        >
          <Controller
            name="noHp"
            control={control}
            render={({ field }) => (
              <Input
                {...field}
                placeholder="Masukkan nomor telephone anda yang aktif"
              />
            )}
          />
        </Form.Item>

        <Form.Item
          name="noTel"
          label={
            <div className="flex items-center">
              <label className="mr-2 font-medium">
                Nomor Telephone (Rumah)
              </label>
            </div>
          }
          validateStatus={errors.noTel ? "error" : ""}
          help={errors.noTel ? errors.noTel.message : ""}
        >
          <Controller
            name="noTel"
            control={control}
            render={({ field }) => (
              <Input
                {...field}
                placeholder="Masukkan nomor telephone rumah anda"
              />
            )}
          />
        </Form.Item>

        <div className="flex">
          <div className="w-1/2 pr-2">
            <Form.Item
              name="namaKontakDarurat"
              label={
                <div className="flex items-center">
                  <label className="mr-2 font-medium">Kontak Darurat</label>
                  <span className="text-red-500">*</span>
                </div>
              }
              validateStatus={errors.namaKontakDarurat ? "error" : ""}
              help={
                errors.namaKontakDarurat ? errors.namaKontakDarurat.message : ""
              }
            >
              <Controller
                name="namaKontakDarurat"
                control={control}
                render={({ field }) => (
                  <Input {...field} placeholder="Nama Kontak" />
                )}
              />
            </Form.Item>
          </div>
          <div className="w-1/2 pl-2">
            <Form.Item
              name="hubunganKontakDarurat"
              label={<div className="flex items-center"></div>}
              validateStatus={errors.hubunganKontakDarurat ? "error" : ""}
              help={
                errors.hubunganKontakDarurat
                  ? errors.hubunganKontakDarurat.message
                  : ""
              }
            >
              <Controller
                name="hubunganKontakDarurat"
                control={control}
                render={({ field }) => (
                  <Input {...field} placeholder="Hubungan" />
                )}
              />
            </Form.Item>
          </div>
        </div>

        <Form.Item
          name="noKontakDarurat"
          label={
            <div className="flex items-center">
              <label className="mr-2 font-medium">
                Nomor Telephone Kontak Darurat
              </label>
              <span className="text-red-500">*</span>
            </div>
          }
          validateStatus={errors.noKontakDarurat ? "error" : ""}
          help={errors.noKontakDarurat ? errors.noKontakDarurat.message : ""}
        >
          <Controller
            name="noKontakDarurat"
            control={control}
            render={({ field }) => (
              <Input
                {...field}
                placeholder="Masukkan nomor telephone kontak darurat"
              />
            )}
          />
        </Form.Item>

        <Form.Item
          name="alamatEmail"
          label={
            <div className="flex items-center">
              <label className="mr-2 font-medium">Alamat Email</label>
              <span className="text-red-500">*</span>
            </div>
          }
          validateStatus={errors.alamatEmail ? "error" : ""}
          help={errors.alamatEmail ? errors.alamatEmail.message : ""}
        >
          <Controller
            name="alamatEmail"
            control={control}
            render={({ field }) => (
              <Input {...field} placeholder="Masukkan email anda yang aktif" />
            )}
          />
        </Form.Item>

        <Form.Item
          name="agama"
          label={
            <div className="flex items-center">
              <label className="mr-2 font-medium">Agama</label>
              <span className="text-red-500">*</span>
            </div>
          }
          validateStatus={errors.agama ? "error" : ""}
          help={errors.agama ? errors.agama.message : ""}
        >
          <Controller
            name="agama"
            control={control}
            render={({ field }) => (
              <div className="flex items-center">
                <Select
                  placeholder="Pilih agama anda"
                  filterSort={(optionA, optionB) =>
                    optionA.value - optionB.value
                  }
                  options={religionsList()}
                  {...field}
                ></Select>
              </div>
            )}
          />
        </Form.Item>

        <Form.Item
          name="jumlahSaudara"
          label={
            <div className="flex items-center">
              <label className="mr-2 font-medium">Jumlah Saudara</label>
              <span className="text-red-500">*</span>
            </div>
          }
          validateStatus={
            errors.jumlahSaudara ||
              errors.noSaudara ||
              jumlahSaudaraValue < noSaudaraValue
              ? "error"
              : ""
          }
          help={
            jumlahSaudaraValue < noSaudaraValue
              ? "Jumlah saudara harus lebih besar dari Anak ke-x"
              : ""
          }
        >
          <div className="flex">
            <Controller
              name="noSaudara"
              control={control}
              render={({ field }) => (
                <div className="">
                  <label className="mx-1">Anak Ke</label>
                  <Input
                    className="w-16"
                    type="number"
                    {...field}
                    onChange={(e) => field.onChange(parseInt(e.target.value))}
                  />
                </div>
              )}
            />
            <Controller
              name="jumlahSaudara"
              control={control}
              render={({ field }) => (
                <div>
                  <label className="mx-1">Dari</label>
                  <Input
                    className="w-16"
                    type="number"
                    {...field}
                    onChange={(e) => field.onChange(parseInt(e.target.value))}
                  />
                </div>
              )}
            />
            <Controller
              name="jumlahSaudara"
              control={control}
              render={() => (
                <div className="mt-1">
                  <label className="mx-1">Bersaudara</label>
                </div>
              )}
            />
          </div>
          <div>
            <p className="text-red-500 mr-3">{errors["noSaudara"]?.message}</p>
            <p className="text-red-500">{errors["jumlahSaudara"]?.message}</p>
          </div>
        </Form.Item>

        <Form.Item
          name="statusPernikahan"
          label={
            <div className="flex items-center">
              <label className="mr-2 font-medium">Status Pernikahan</label>
              <span className="text-red-500">*</span>
            </div>
          }
          validateStatus={errors.statusPernikahan ? "error" : ""}
          help={errors.statusPernikahan ? errors.statusPernikahan.message : ""}
        >
          <Controller
            name="statusPernikahan"
            control={control}
            render={({ field }) => (
              <div className="flex flex-col">
                <div className="flex">
                  <label className="flex items-center space-x-2">
                    <input
                      type="radio"
                      {...field}
                      checked={field.value === "1"}
                      onChange={() => field.onChange("1")}
                      className="appearance-none border-2 border-[#948A54] rounded-full w-4 h-4 checked:bg-[#948A54] checked:border-[#948A54] inline-flex items-center justify-center"
                    />
                    <span>Lajang</span>
                  </label>
                  <label className="flex items-center space-x-2 ml-5">
                    <input
                      type="radio"
                      {...field}
                      checked={field.value === "2"}
                      onChange={() => field.onChange("2")}
                      className="appearance-none border-2 border-[#948A54] rounded-full w-4 h-4 checked:bg-[#948A54] checked:border-[#948A54] inline-flex items-center justify-center"
                    />
                    <span>Menikah</span>
                  </label>
                  <label className="flex items-center space-x-2 ml-5">
                    <input
                      type="radio"
                      {...field}
                      checked={field.value === "3"}
                      onChange={() => field.onChange("3")}
                      className="appearance-none border-2 border-[#948A54] rounded-full w-4 h-4 checked:bg-[#948A54] checked:border-[#948A54] inline-flex items-center justify-center"
                    />
                    <span>Bercerai</span>
                  </label>
                </div>
              </div>
            )}
          />
        </Form.Item>

        <Form.Item
          name="jumlahAnak"
          label={
            <div className="flex items-center">
              <label className="mr-2 font-medium">
                Jumlah Anak (Untuk Keperluan Status Pajak Penghasilan)
              </label>
            </div>
          }
          validateStatus={errors.jumlahAnak ? "error" : ""}
          help={errors.jumlahAnak ? errors.jumlahAnak.message : ""}
        >
          <Controller
            name="jumlahAnak"
            control={control}
            render={({ field }) => (
              <Input {...field} placeholder="Masukkan jumlah anak" />
            )}
          />
        </Form.Item>

        <Form.Item
          name="nationalities"
          label={
            <div className="flex items-center">
              <label className="mr-2 font-medium">Kewarganegaraan</label>
              <span className="text-red-500">*</span>
            </div>
          }
          validateStatus={errors.kewarganegaraan ? "error" : ""}
          help={errors.kewarganegaraan ? errors.kewarganegaraan.message : ""}
        >
          <Controller
            name="kewarganegaraan"
            control={control}
            render={({ field }) => (
              <div className="flex">
                <label className="flex items-center space-x-2">
                  <input
                    type="radio"
                    {...field}
                    checked={field.value === "WNI"}
                    onChange={() => field.onChange("WNI")}
                    className="appearance-none border-2 border-[#948A54] rounded-full w-4 h-4 checked:bg-[#948A54] checked:border-[#948A54] inline-flex items-center justify-center"
                  />
                  <span>WNI</span>
                </label>
                <label className="flex items-center space-x-2 ml-5">
                  <input
                    type="radio"
                    {...field}
                    checked={field.value === "WNA"}
                    onChange={() => field.onChange("WNA")}
                    className="appearance-none border-2 border-[#948A54] rounded-full w-4 h-4 checked:bg-[#948A54] checked:border-[#948A54] inline-flex items-center justify-center"
                  />
                  <span>WNA</span>
                </label>
              </div>
            )}
          ></Controller>
        </Form.Item>

        <Form.Item
          name="medSosStatus"
          label={
            <div className="flex items-center">
              <label className="mr-2 font-medium">Media Sosial</label>
              <span className="text-red-500">*</span>
            </div>
          }
          validateStatus={
            errors.medSosStatus ||
              (watch("medSosStatus") === "2" && errors.medSosUsername) ||
              (watch("medSosStatus") === "2" && errors.medSos)
              ? "error"
              : ""
          }
          help={errors.medSosStatus ? errors.medSosStatus.message : ""}
        >
          <Controller
            name="medSosStatus"
            control={control}
            render={({ field }) => (
              <div className="flex flex-col">
                <div className="flex">
                  <label className="flex items-center space-x-2">
                    <input
                      type="radio"
                      {...field}
                      checked={field.value === "2"}
                      onChange={() => field.onChange("2")}
                      className="appearance-none border-2 border-[#948A54] rounded-full w-4 h-4 checked:bg-[#948A54] checked:border-[#948A54] inline-flex items-center justify-center"
                    />
                    <span>Memiliki media sosial</span>
                  </label>
                  <label className="flex items-center space-x-2 ml-5">
                    <input
                      type="radio"
                      {...field}
                      checked={field.value === "1"}
                      onChange={() => field.onChange("1")}
                      className="appearance-none border-2 border-[#948A54] rounded-full w-4 h-4 checked:bg-[#948A54] checked:border-[#948A54] inline-flex items-center justify-center"
                    />
                    <span>Tidak memiliki media sosial</span>
                  </label>
                </div>
              </div>
            )}
          ></Controller>

          {watch("medSosStatus") === "2" && (
            <>
              <div className="flex mt-2">
                <div className="w-1/2 pr-2">
                  <Form.Item
                    name="medSos"
                    validateStatus={errors.medSos ? "error" : ""}
                    help={errors.medSos ? errors.medSos.message : ""}
                    style={{ height: 27 }}
                  >
                    <Controller
                      control={control}
                      name="medSos"
                      render={({ field }) => (
                        <Select
                          placeholder="Pilih salah satu"
                          options={[
                            {
                              value: "5",
                              label: (
                                <span style={{ color: "#ccc" }}>
                                  Pilih salah satu
                                </span>
                              ),
                              disabled: true,
                            },
                            ...socialMediasList(), // Opsi lainnya
                          ]}
                          {...field}
                        />
                      )}
                    ></Controller>
                  </Form.Item>
                </div>
                <div className="w-1/2 pl-2">
                  <Form.Item
                    name="medSosUsername"
                    validateStatus={errors.medSosUsername ? "error" : ""}
                    help={
                      errors.medSosUsername ? errors.medSosUsername.message : ""
                    }
                    style={{ height: 0 }}
                  >
                    <Controller
                      control={control}
                      name="medSosUsername"
                      render={({ field }) => (
                        <Input
                          {...field}
                          placeholder="Masukkan nama akun atau link"
                        />
                      )}
                    />
                  </Form.Item>
                </div>
              </div>
            </>
          )}
        </Form.Item>

        <Form.Item
          name="riwayatPenyakit"
          label={
            <div className="flex items-center">
              <label className="mr-2 font-medium">
                Riwayat Penyakit Berat (Jika Ada)
              </label>
            </div>
          }
          validateStatus={errors.riwayatPenyakit ? "error" : ""}
          help={errors.riwayatPenyakit ? errors.riwayatPenyakit.message : ""}
        >
          <Controller
            name="riwayatPenyakit"
            control={control}
            render={({ field }) => (
              <Input {...field} placeholder="Masukkan riwayat penyakit" />
            )}
          />
        </Form.Item>
        <Form.Item wrapperCol={{ offset: 0, span: 16 }}>
          <Button
            className="bg-[#948A54] mr-2"
            type="primary"
            onClick={() => onPrevPage()}
          >
            Kembali
          </Button>
          <Button className="bg-[#948A54]" type="primary" htmlType="submit">
            Lanjutkan
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};
export default PersonalDataForm;
