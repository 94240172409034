import { Button, Form, Input, InputNumber, Select } from "antd";
import React, { useState } from "react";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { Controller, useForm } from "react-hook-form";
import { useEffect } from "react";
import { HeaderForm } from "./HeaderForm";
import { BackendApiUrl } from "@/functions/BackendApiUrl";
import useSWR from "swr";
import { useSwrFetcherWithAccessToken } from "@/functions/useSwrFetcherWithAccessToken";
import { SelectOption, Dropdown } from "@/interfaces/SelectOption";

const FormSchema = z.object({
  position: z
    .string({
      required_error: "Posisi dilamar tidak boleh kosong",
    })
    .min(0, {
      message: "Posisi dilamar tidak boleh kosong",
    }),
  name: z
    .string({
      required_error: "Nama tidak boleh kosong",
    })
    .nonempty({
      message: "Nama tidak boleh kosong",
    })
    .max(255, {
      message: "Nama harus kurang dari 255 karakter",
    })
    .refine((value) => !/^\s*$/.test(value), {
      message: "Tidak boleh hanya berisi spasi",
    }),
  carrerGoals: z
    .string({
      required_error: "Tujuan Karir tidak boleh kosong",
    })
    .nonempty({
      message: "Tujuan karir tidak boleh kosong",
    })
    .refine((value) => !/^\s*$/.test(value), {
      message: "Tidak boleh hanya berisi spasi",
    })
    .refine(
      (value) => {
        const wordCount = value.split(/\s+/).length;
        return wordCount <= 250;
      },
      {
        message: "Tujuan karir maksimal 250 kata",
      }
    ),
  reasonApply: z
    .string({
      required_error: "Alasan melamar tidak boleh kosong",
    })
    .nonempty({
      message: "Alasan melamar tidak boleh kosong",
    })
    .refine(
      (value) => {
        const wordCount = value.split(/\s+/).length;
        return wordCount <= 250;
      },
      {
        message: "Alasan melamar maksimal 250 kata",
      }
    )
    .refine((value) => !/^\s*$/.test(value), {
      message: "Tidak boleh hanya berisi spasi",
    }),
  incomeStatus: z
    .number({
      required_error: "Status penghasilan tidak boleh kosong",
    })
    .min(0, {
      message: "Status penghasilan tidak boleh kosong",
    }),
  income: z
    .number({
      required_error: "Nominal penghasilan tidak boleh kosong",
      invalid_type_error: "Nominal penghasilan tidak boleh kosong",
    })
    .min(0, {
      message: "Nominal penghasilan harus lebih dari -1",
    }),
  statusPrev: z
    .string({
      required_error: "Status karyawan sebelumnya tidak boleh kosong",
    })
    .nonempty({
      message: "Status karyawan sebelumnya tidak boleh kosong",
    }),
  expectedIncome: z
    .string({
      required_error: "Penghasilan tidak boleh kosong",
    })
    .nonempty({
      message: "Penghasilan tidak boleh kosong",
    })
    .max(100, {
      message: "Penghasilan harus kurang dari 100 karakter",
    })
    .refine((value) => !/^\s*$/.test(value), {
      message: "Tidak boleh hanya berisi spasi",
    })
    .refine(
      (value) => {
        const numericValue = parseFloat(value);
        return !isNaN(numericValue) && numericValue > 0;
      },
      {
        message: "Penghasilan harus lebih dari 0",
      }
    )
    .refine((value) => /^[0-9]*$/.test(value), {
      message: "Penghasilan harus berupa angka",
    }),
  startWork: z
    .string({
      required_error: "Mulai bekerja tidak boleh kosong",
    })
    .nonempty({
      message: "Mulai bekerja tidak boleh kosong",
    })
    .max(100, {
      message: "Mulai bekerja harus kurang dari 100 karakter",
    }),
  startWorkDate: z
    .string({
      required_error: "Mulai bekerja tidak boleh kosong",
    })
    .refine((value) => !/^\s*$/.test(value), {
      message: "Tidak boleh kosong",
    }),
  otherPosition: z
    .string({
      required_error: "Posisi tidak boleh kosong",
    })
    .refine((value) => !/^\s*$/.test(value), {
      message: "Posisi tidak boleh kosong",
    }),
});

export type applyFormDataType = z.infer<typeof FormSchema>;
const { TextArea } = Input;

const PersonalDataForm: React.FC<{
  onSubmit: (data: applyFormDataType) => void;
  applyForm?: applyFormDataType;
  onNextPage: () => void;
}> = ({ onSubmit, applyForm, onNextPage }) => {
  const {
    control,
    formState: { errors },
    handleSubmit,
    watch,
    setValue,
  } = useForm<applyFormDataType>({
    resolver: zodResolver(FormSchema),
  });

  useEffect(() => {
    if (applyForm) {
      setValue("position", applyForm.position);
      setValue("name", applyForm.name);
      setValue("carrerGoals", applyForm.carrerGoals);
      setValue("reasonApply", applyForm.reasonApply);
      setValue("incomeStatus", applyForm.incomeStatus);
      setValue("income", applyForm.income);
      setValue("statusPrev", applyForm.statusPrev);
      setValue("expectedIncome", applyForm.expectedIncome);
      setValue("startWork", applyForm.startWork);
      setValue("startWorkDate", applyForm.startWorkDate);
      setValue("otherPosition", applyForm.otherPosition);
    }
  }, [applyForm, setValue]);

  function onHandleFormSubmit(data: applyFormDataType) {
    onSubmit(data);
    onNextPage();
  }

  const swrFetcher = useSwrFetcherWithAccessToken();

  const { data: currentSalaryList } = useSWR<SelectOption<number>[]>(
    BackendApiUrl.getCurrentSalary,
    swrFetcher
  );

  const { data: candidatePosition } = useSWR<SelectOption<number>[]>(
    BackendApiUrl.getCandidatePosition,
    swrFetcher
  );

  function currentSalaryLists(): Dropdown<number>[] {
    if (!currentSalaryList) {
      return [];
    }

    return currentSalaryList.map((item) => {
      const row: Dropdown<number> = {
        label: item.name,
        value: item.id,
      };
      return row;
    });
  }

  const hardcodedCandidatePositions = [
    // Add more hardcoded positions as needed
    { name: "Lainnya", id: "lainnya" }, // Add this line for "Lainnya"
  ];

  function candidatePositions(): Dropdown<string>[] {
    if (!candidatePosition) {
      return [];
    }

    // Combine hardcoded positions with fetched positions
    const combinedPositions = [
      ...hardcodedCandidatePositions,
      ...candidatePosition,
    ];

    return combinedPositions.map((item) => {
      const row: Dropdown<string> = {
        label: item.name,
        value: item.name,
      };
      return row;
    });
  }

  const [firstVisit, setFirstVisit] = useState(true);

  useEffect(() => {
    if (firstVisit) {
      window.scrollTo(0, 0);
      setFirstVisit(false);
    }
  }, [firstVisit]);

  useEffect(() => {
    if (watch("startWork") === "1") {
      setValue("startWorkDate", "0");
    } else if (watch("startWork") === "2" && watch("startWorkDate") === "0") {
      setValue("startWorkDate", "");
    } else if (
      applyForm &&
      watch("startWork") === "2" &&
      watch("startWorkDate") !== ""
    ) {
      setValue("startWorkDate", applyForm.startWorkDate);
    }
  }, [watch("startWork")]);

  console.log(applyForm);

  useEffect(() => {
    if (watch("position") !== "Lainnya") {
      setValue("otherPosition", "-");
    } else if (
      watch("position") === "Lainnya" &&
      watch("otherPosition") === "-"
    ) {
      setValue("otherPosition", "");
    } else if (
      applyForm &&
      watch("position") === "Lainnya" &&
      watch("otherPosition") !== ""
    ) {
      setValue("otherPosition", applyForm.otherPosition);
    }
  }, [watch("position")]);

  const [carrerGoalsText, setCarrerGoalsText] = useState('');
  const [reasonApplyText, setReasonApplyText] = useState('');

  const handleTextChange = (e, field, setTextFunction) => {
    const inputText = e.target.value;
    const wordCount = inputText.split(/\s+/).length;

    if (wordCount <= 250) {
      setTextFunction(inputText);
      field.onChange(e);
    }
  };

  // Expected Income 

  const [finalIncome, setFinalIncome] = useState(0);

  // Watch the expectedIncome field for changes
  const expectedIncome = watch('expectedIncome', "0");

  useEffect(() => {
    // Update the finalIncome whenever expectedIncome changes
    const calculatedFinalIncome = calculateFinalIncome(expectedIncome); // Replace with actual calculation
    setFinalIncome(calculatedFinalIncome);
  }, [expectedIncome]);

  const calculateFinalIncome = (income) => {
    const tax = income * 0.5 * 0.05;
    return income - tax;
  };

  const formatToRupiah = new Intl.NumberFormat('id-ID', {
    style: 'currency',
    currency: 'IDR',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).format(finalIncome);

  return (
    <>
      <HeaderForm steps={0} />
      <Form layout="vertical" onFinish={handleSubmit(onHandleFormSubmit)}>
        <Form.Item
          label={
            <div className="flex items-center">
              <label className="mr-2 font-medium">Posisi Dilamar</label>
              <span className="text-red-500">*</span>
            </div>
          }
          validateStatus={
            errors.position ||
              (watch("position") === "Lainnya" && errors.otherPosition)
              ? "error"
              : ""
          }
          help={errors.position ? errors.position.message : ""}
        >
          <Controller
            control={control}
            name="position"
            render={({ field }) => (
              <>
                <Select
                  placeholder="Pilih nama posisi"
                  filterSort={(optionA, optionB) => {
                    // sorting value Lainnya to the bottom
                    if (optionA.value === "Lainnya") return 1;
                    if (optionB.value === "Lainnya") return -1;
                    return optionA.value - optionB.value;
                  }}
                  options={candidatePositions()}
                  {...field}
                />
              </>
            )}
          />
          {watch("position") === "Lainnya" && (
            <>
              <Controller
                name="otherPosition"
                control={control}
                render={({ field }) => (
                  <Input
                    className="mt-3"
                    placeholder="Masukkan posisi lainnya"
                    {...field}
                  />
                )}
              />

              <div>
                <p className="text-red-500">
                  {errors["otherPosition"]?.message}
                </p>
              </div>
            </>
          )}
        </Form.Item>

        <Form.Item
          label={
            <div className="flex items-center">
              <label className="mr-2 font-medium">Nama Lengkap</label>
              <span className="text-red-500">*</span>
            </div>
          }
          validateStatus={errors.name ? "error" : ""}
          help={errors.name ? errors.name.message : ""}
        >
          <Controller
            name="name"
            control={control}
            render={({ field }) => (
              <Input {...field} placeholder="Masukkan nama lengkap" />
            )}
          />
        </Form.Item>

        <Form.Item
          name="carrerGoals"
          label={
            <div className="flex items-center">
              <label className="mr-2 font-medium">Apa Tujuan Karir Anda?</label>
              <span className="text-red-500">*</span>
            </div>
          }
          validateStatus={errors.carrerGoals ? "error" : ""}
          help={errors.carrerGoals ? errors.carrerGoals.message : ""}
        >
          <Controller
            name="carrerGoals"
            control={control}
            render={({ field }) => (
              <div style={{ position: 'relative' }}>
                <TextArea
                  {...field}
                  rows={4}
                  placeholder="Maximal 250 kata"
                  value={watch("carrerGoals")}
                  onChange={(e) => handleTextChange(e, field, setCarrerGoalsText)}
                />
                <div
                  style={{
                    position: 'absolute',
                    bottom: 8,
                    right: 8,
                    color: carrerGoalsText.split(/\s+/).filter((word) => word).length > 250 ? 'red' : 'gray',
                  }}
                >
                  {carrerGoalsText.split(/\s+/).filter((word) => word).length}/250
                </div>
              </div>

            )}
          />
        </Form.Item>

        <Form.Item
          name="reasonApply"
          label={
            <div className="flex items-center">
              <label className="mr-2 font-medium">
                Apa Alasan Anda Melamar Di Perusahaan Kami?
              </label>
              <span className="text-red-500">*</span>
            </div>
          }
          validateStatus={errors.reasonApply ? "error" : ""}
          help={errors.reasonApply ? errors.reasonApply.message : ""}
        >
          <Controller
            name="reasonApply"
            control={control}
            render={({ field }) => (
              <div style={{ position: 'relative' }}>
                <TextArea
                  {...field}
                  rows={4}
                  placeholder="Maximal 250 kata"
                  value={watch("reasonApply")}
                  onChange={(e) => handleTextChange(e, field, setReasonApplyText)}
                />
                <div
                  style={{
                    position: 'absolute',
                    bottom: 8,
                    right: 8,
                    color: reasonApplyText.split(/\s+/).filter((word) => word).length > 250 ? 'red' : 'gray',
                  }}
                >
                  {reasonApplyText.split(/\s+/).filter((word) => word).length}/250
                </div>
              </div>
            )}
          />
        </Form.Item>

        <div className="items-center mb-0 p-0">
          <label className="mr-2 font-medium">
            Penghasilan Anda Saat Ini (Gross / Take Home Pay)
          </label>
          <span className="text-red-500">*</span>
          <p className="text-red-500 italic">
            Anda akan diminta melampirkan slip gaji saat ini
          </p>
        </div>
        <div className="flex mt-0 p-0 min-h-0">
          <div className="w-1/2 pr-2">
            <Form.Item
              name="incomeStatus"
              validateStatus={errors.incomeStatus ? "error" : ""}
              help={errors.incomeStatus ? errors.incomeStatus.message : ""}
              style={{ minHeight: 0 }}
            >
              <Controller
                control={control}
                name="incomeStatus"
                render={({ field }) => (
                  <Select
                    placeholder="Pilih salah satu"
                    options={currentSalaryLists()}
                    {...field}
                  />
                )}
              ></Controller>
            </Form.Item>
          </div>
          <div className="w-1/2 pl-2 ml-2">
            <Form.Item
              name="income"
              validateStatus={errors.income ? "error" : ""}
              help={errors.income ? errors.income.message : ""}
            >
              <Controller
                control={control}
                name="income"
                render={({ field }) => (
                  <div>
                    <Input
                      type="number"
                      {...field}
                      onChange={(e) => field.onChange(parseInt(e.target.value))}
                      placeholder="Nominal penghasilan"
                    />
                  </div>
                )}
              />
            </Form.Item>
          </div>
        </div>

        <Form.Item
          name="statusPrev"
          label={
            <div className="flex items-center">
              <label className="mr-2 font-medium">
                Status Karyawan Di Perusahaan Sebelumnya
              </label>
              <span className="text-red-500">*</span>
            </div>
          }
          validateStatus={errors.statusPrev ? "error" : ""}
          help={errors.statusPrev ? errors.statusPrev.message : ""}
        >
          <div className="flex flex-col">
            <Controller
              name="statusPrev"
              control={control}
              render={({ field }) => (
                <div className="flex">
                  <label className="flex items-center space-x-2">
                    <input
                      type="radio"
                      {...field}
                      checked={field.value === "1"}
                      onChange={() => field.onChange("1")}
                      className="appearance-none border-2 border-[#948A54] rounded-full w-4 h-4 checked:bg-[#948A54] checked:border-[#948A54] inline-flex items-center justify-center"
                    />
                    <span>Permanen</span>
                  </label>
                  <label className="flex items-center space-x-2 ml-5">
                    <input
                      type="radio"
                      {...field}
                      checked={field.value === "2"}
                      onChange={() => field.onChange("2")}
                      className="appearance-none border-2 border-[#948A54] rounded-full w-4 h-4 checked:bg-[#948A54] checked:border-[#948A54] inline-flex items-center justify-center"
                    />
                    <span>Kontrak</span>
                  </label>
                  <label className="flex items-center space-x-2 ml-5">
                    <input
                      type="radio"
                      {...field}
                      checked={field.value === "3"}
                      onChange={() => field.onChange("3")}
                      className="appearance-none border-2 border-[#948A54] rounded-full w-4 h-4 checked:bg-[#948A54] checked:border-[#948A54] inline-flex items-center justify-center"
                    />
                    <span>Belum Bekerja</span>
                  </label>
                </div>
              )}
            ></Controller>
          </div>
        </Form.Item>

        <Form.Item
          name="expectedIncome"
          label={
            <>
              <div className="flex flex-col justify-center items-start">
                <div>
                  <label className="mr-2 font-medium">Penghasilan Yang Diharapkan <span className="font-bold">(gross - all in)</span></label>
                  <span className="text-red-500">*</span>
                </div>
                <p className="text-sm text-gray-400">(Dalam penghasilan yang diharapkan ini mohon diisi dengan nominal yang sudah termasuk perhitungan Pajak, THR, dan BPJS)
                </p>
              </div>
            </>
          }
          validateStatus={errors.expectedIncome ? "error" : ""}
          help={errors.expectedIncome ? errors.expectedIncome.message : ""}
        >
          <div className="flex flex-col">
            <Controller
              name="expectedIncome"
              control={control}
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <InputNumber<number | string>
                  defaultValue={0}
                  className="w-full"
                  formatter={(value) => `Rp ${value}`.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
                  parser={(value) => value?.replace(/Rp\s?|(\.*)/g, '') as unknown as number}
                  onChange={(value) => onChange(value?.toString())}
                />
              )}
            />
          </div>
          <div>
            <p className="italic text-xs">estimasi <span className="font-bold">take home pay</span> (setelah dipotong pph21): {formatToRupiah}</p>
          </div>
        </Form.Item>
        <Form.Item
          name="startWork"
          label={
            <div className="flex items-center">
              <label className="mr-2 font-medium">
                Kapan Anda Dapat Bekerja Dengan Kami?
              </label>
              <span className="text-red-500">*</span>
            </div>
          }
          validateStatus={
            errors.startWork ||
              (watch("startWork") === "2" && errors.startWorkDate)
              ? "error"
              : ""
          }
          help={errors.startWork ? errors.startWork.message : ""}
        >
          <Controller
            name="startWork"
            control={control}
            render={({ field }) => (
              <div className="flex mb-3">
                <label className="flex items-center space-x-2">
                  <input
                    type="radio"
                    {...field}
                    checked={field.value === "1"}
                    onChange={() => field.onChange("1")}
                    className="appearance-none border-2 border-[#948A54] rounded-full w-4 h-4 checked:bg-[#948A54] checked:border-[#948A54] inline-flex items-center justify-center"
                  />
                  <span>Secepatnya</span>
                </label>
                <label className="flex items-center space-x-2 ml-5">
                  <input
                    type="radio"
                    {...field}
                    checked={field.value === "2"}
                    onChange={() => field.onChange("2")}
                    className="appearance-none border-2 border-[#948A54] rounded-full w-4 h-4 checked:bg-[#948A54] checked:border-[#948A54] inline-flex items-center justify-center"
                  />
                  <span>Masukkan tanggal</span>
                </label>
              </div>
            )}
          />

          {watch("startWork") === "2" && (
            <>
              <Controller
                name="startWorkDate"
                control={control}
                render={({ field }) => <Input {...field} type="date" />}
              />
              <div>
                <p className="text-red-500">
                  {errors["startWorkDate"]?.message}
                </p>
              </div>
            </>
          )}
        </Form.Item>

        <Form.Item wrapperCol={{ offset: 0, span: 16 }}>
          <Button className="bg-[#948A54]" type="primary" htmlType="submit">
            Lanjutkan
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};
export default PersonalDataForm;