import React from "react";
import { Modal, Button } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleExclamation } from "@fortawesome/free-solid-svg-icons";

interface PopUpProps {
  visible: boolean;
  onCancel: () => void;
  onSubmit: () => void;
  onFirstPage: () => void;
}

const PopUp: React.FC<PopUpProps> = ({ visible, onCancel, onSubmit, onFirstPage }) => {
  
  const handleSuccessClick = () => {
    onCancel();
    onSubmit();
    // Menampilkan PopUpSuccess
  };

  return (
    <div>
      <Modal
        title={
          <div className="flex flex-col items-center text-center">
            <FontAwesomeIcon
              icon={faCircleExclamation}
              className="text-red-500 text-7xl mb-2 mt-5"
            />
            <div className="text-3xl font-bold mt-5">
              Konfirmasi Pengumpulan
            </div>
          </div>
        }
        visible={visible}
        onCancel={onCancel}
        maskClosable={false}
        footer={
          <div className="flex justify-center mt-10">
            <Button key="cekUlang" onClick={onCancel}>
              Cek Ulang
            </Button>
            <Button
              key="kumpulkan"
              onClick={handleSuccessClick}
              className="bg-[#948A54] text-white"
            >
              Kumpulkan
            </Button>
          </div>
        }
      >
        <p className="text-lg text-center mt-5">
          Pastikan bahwa semua data yang diisi sudah benar,{" "}
          <span className="text-red-500 ">
            pengisian form tidak dapat diulangi kembali
          </span>
        </p>
      </Modal>

      {/* PopUpSuccess */}
      
    </div>
  );
};

export default PopUp;
