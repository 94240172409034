import { Button, Form, Input } from "antd";
import React, { useEffect, useState } from "react";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm, useFieldArray, Controller } from "react-hook-form";
import { HeaderForm } from "./HeaderForm";

const FormSchema = z.object({
  namaLengkapAyah: z
    .string({
      required_error: "Nama lengkap tidak boleh kosong",
    })
    .nonempty({
      message: "Nama lengkap tidak boleh kosong",
    })
    .max(255, {
      message: "Nama lengkap harus kurang dari 255 karakter",
    })
    .refine((value) => !/^\s*$/.test(value), {
      message: "Tidak boleh hanya berisi spasi",
    }),
  tempatLahirAyah: z
    .string({
      required_error: "Tempat lahir tidak boleh kosong",
    })
    .nonempty({
      message: "Tempat lahir tidak boleh kosong",
    })
    .max(255, {
      message: "Tempat lahir harus kurang dari 255 karakter",
    })
    .refine((value) => !/^\s*$/.test(value), {
      message: "Tidak boleh hanya berisi spasi",
    }),
  tglLahirAyah: z
    .string({
      required_error: "Tanggal lahir tidak boleh kosong",
    })
    .nonempty({
      message: "Tanggal lahir tidak boleh kosong",
    })
    .refine(
      (value) => {
        if (!value) return true;
        const inputDate = new Date(value);
        const today = new Date();
        return inputDate <= today;
      },
      {
        message: "Tanggal tidak boleh lebih besar dari tanggal hari ini",
      }
    ),
  pekerjaanAyah: z
    .string({
      required_error: "Pekerjaan tidak boleh kosong",
    })
    .nonempty({
      message: "Pekerjaan tidak boleh kosong",
    })
    .max(255, {
      message: "Pekerjaan harus kurang dari 255 karakter",
    })
    .refine((value) => !/^\s*$/.test(value), {
      message: "Tidak boleh hanya berisi spasi",
    }),
  namaLengkapIbu: z
    .string({
      required_error: "Nama lengkap tidak boleh kosong",
    })
    .nonempty({
      message: "Nama lengkap tidak boleh kosong",
    })
    .max(255, {
      message: "Nama lengkap harus kurang dari 255 karakter",
    })
    .refine((value) => !/^\s*$/.test(value), {
      message: "Tidak boleh hanya berisi spasi",
    }),
  tempatLahirIbu: z
    .string({
      required_error: "Tempat lahir tidak boleh kosong",
    })
    .nonempty({
      message: "Tempat lahir tidak boleh kosong",
    })
    .max(255, {
      message: "Tempat lahir harus kurang dari 255 karakter",
    })
    .refine((value) => !/^\s*$/.test(value), {
      message: "Tidak boleh hanya berisi spasi",
    }),
  tglLahirIbu: z
    .string({
      required_error: "Tanggal lahir tidak boleh kosong",
    })
    .nonempty({
      message: "Tanggal lahir tidak boleh kosong",
    })
    .refine(
      (value) => {
        if (!value) return true;
        const inputDate = new Date(value);
        const today = new Date();
        return inputDate <= today;
      },
      {
        message: "Tanggal tidak boleh lebih besar dari tanggal hari ini",
      }
    ),
  pekerjaanIbu: z
    .string({
      required_error: "Pekerjaan tidak boleh kosong",
    })
    .nonempty({
      message: "Pekerjaan tidak boleh kosong",
    })
    .max(255, {
      message: "Pekerjaan harus kurang dari 255 karakter",
    })
    .refine((value) => !/^\s*$/.test(value), {
      message: "Tidak boleh hanya berisi spasi",
    }),
  namaLengkapAnak: z
    .string({
      required_error: "Nama lengkap tidak boleh kosong",
    })
    .nonempty({
      message: "Nama lengkap tidak boleh kosong",
    })
    .max(255, {
      message: "Nama lengkap harus kurang dari 255 karakter",
    })
    .refine((value) => !/^\s*$/.test(value), {
      message: "Tidak boleh hanya berisi spasi",
    }),
  tempatLahirAnak: z
    .string({
      required_error: "Tempat lahir tidak boleh kosong",
    })
    .nonempty({
      message: "Tempat lahir tidak boleh kosong",
    })
    .max(255, {
      message: "Tempat lahir harus kurang dari 255",
    })
    .refine((value) => !/^\s*$/.test(value), {
      message: "Tidak boleh hanya berisi spasi",
    }),
  tgglLahirAnak: z
    .string({
      required_error: "Tanggal lahir tidak boleh kosong",
    })
    .nonempty({
      message: "Tanggal lahir tidak boleh kosong",
    })
    .refine(
      (value) => {
        if (!value) return true;
        const inputDate = new Date(value);
        const today = new Date();
        return inputDate <= today;
      },
      {
        message: "Tanggal tidak boleh lebih besar dari tanggal hari ini",
      }
    ),
  jenisKelaminAnak: z
    .string({
      required_error: "Jenis kelamin tidak boleh kosong",
    })
    .nonempty({
      message: "Jenis kelamin tidak boleh kosong",
    }),
  pekerjaanAnak: z
    .string({
      required_error: "Pekerjaan tidak boleh kosong",
    })
    .nonempty({
      message: "Pekerjaan tidak boleh kosong",
    })
    .max(255, {
      message: "Pekerjaan harus kurang dari 255 karakter",
    })
    .refine((value) => !/^\s*$/.test(value), {
      message: "Tidak boleh hanya berisi spasi",
    }),
  child: z
    .array(
      z.object({
        namaLengkap: z
          .string()
          .nonempty({
            message: "Nama lengkap tidak boleh kosong",
          })
          .max(255, {
            message: "Nama lengkap harus kurang dari 255 karakter",
          })
          .refine((value) => !/^\s*$/.test(value), {
            message: "Tidak boleh hanya berisi spasi",
          }),
        tempatLahir: z
          .string()
          .nonempty({
            message: "Tempat lahir tidak boleh kosong",
          })
          .max(255, {
            message: "Tempat lahir harus kurang dari 255",
          })
          .refine((value) => !/^\s*$/.test(value), {
            message: "Tidak boleh hanya berisi spasi",
          }),
        tgglLahir: z
          .string({
            required_error: "Tanggal lahir tidak boleh kosong",
          })
          .nonempty({
            message: "Tanggal lahir tidak boleh kosong",
          })
          .refine(
            (value) => {
              if (!value) return true;
              const inputDate = new Date(value);
              const today = new Date();
              return inputDate <= today;
            },
            {
              message: "Tanggal tidak boleh lebih besar dari tanggal hari ini",
            }
          ),
        jenisKelamin: z.string().nonempty({
          message: "Jenis kelamin tidak boleh kosong",
        }),
        pekerjaan: z
          .string()
          .nonempty({
            message: "Pekerjaan tidak boleh kosong",
          })
          .max(255, {
            message: "Pekerjaan harus kurang dari 255 karakter",
          })
          .refine((value) => !/^\s*$/.test(value), {
            message: "Tidak boleh hanya berisi spasi",
          }),
      })
    )
    .min(0),
});

export type biologicalFamilyFormDataType = z.infer<typeof FormSchema>;

const BiologicalFamilyForm: React.FC<{
  onSubmit: (data: biologicalFamilyFormDataType) => void;
  biologicalFamilyForm?: biologicalFamilyFormDataType;
  onNextPage: () => void;
  onPrevPage: () => void;
}> = ({ onSubmit, biologicalFamilyForm, onNextPage, onPrevPage }) => {
  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm<biologicalFamilyFormDataType>({
    defaultValues: {
      tglLahirAyah: "",
      tglLahirIbu: "",
      tgglLahirAnak: "",
    },
    resolver: zodResolver(FormSchema),
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "child",
  });

  useEffect(() => {
    if (biologicalFamilyForm) {
      setValue("namaLengkapAyah", biologicalFamilyForm.namaLengkapAyah);
      setValue("tempatLahirAyah", biologicalFamilyForm.tempatLahirAyah);
      setValue("tglLahirAyah", biologicalFamilyForm.tglLahirAyah);
      setValue("pekerjaanAyah", biologicalFamilyForm.pekerjaanAyah);
      setValue("child", biologicalFamilyForm.child);
      setValue("namaLengkapIbu", biologicalFamilyForm.namaLengkapIbu);
      setValue("tempatLahirIbu", biologicalFamilyForm.tempatLahirIbu);
      setValue("tglLahirIbu", biologicalFamilyForm.tglLahirIbu);
      setValue("pekerjaanIbu", biologicalFamilyForm.pekerjaanIbu);
      setValue("namaLengkapAnak", biologicalFamilyForm.namaLengkapAnak);
      setValue("tempatLahirAnak", biologicalFamilyForm.tempatLahirAnak);
      setValue("tgglLahirAnak", biologicalFamilyForm.tgglLahirAnak);
      setValue("jenisKelaminAnak", biologicalFamilyForm.jenisKelaminAnak);
      setValue("pekerjaanAnak", biologicalFamilyForm.pekerjaanAnak);
    }
  }, [biologicalFamilyForm, setValue]);

  function onHandleFormSubmit(data: biologicalFamilyFormDataType) {
    onSubmit(data);
    onNextPage();
  }

  const [firstVisit, setFirstVisit] = useState(true);

  useEffect(() => {
    if (firstVisit) {
      // Jika ini adalah kunjungan pertama, arahkan ke atas halaman
      window.scrollTo(0, 0);

      // Setel status kunjungan pertama menjadi false
      setFirstVisit(false);
    }
  }, [firstVisit]);

  return (
    <>
      <HeaderForm steps={2} />
      <Form layout="vertical" onFinish={handleSubmit(onHandleFormSubmit)}>
        <div className="font-bold text-xl mb-2 mt-5">
          C. DATA KELUARGA KANDUNG
        </div>
        <div className="text-gray-500 text-center italic">
          (isi (-) jika tidak mengetahui informasi berikut)
        </div>

        <div className="font-bold text-xl mb-2">Ayah</div>

        <Form.Item
          name="namaLengkap"
          label={
            <div className="flex items-center">
              <label className="mr-2 font-medium">Nama Lengkap</label>
              <span className="text-red-500">*</span>
            </div>
          }
          validateStatus={errors.namaLengkapAyah ? "error" : ""}
          help={errors.namaLengkapAyah ? errors.namaLengkapAyah.message : ""}
        >
          <Controller
            name="namaLengkapAyah"
            control={control}
            render={({ field }) => (
              <Input {...field} placeholder="Masukkan nama lengkap" />
            )}
          />
        </Form.Item>

        <Form.Item
          name="tempatLahirAyah"
          label={
            <div className="flex items-center">
              <label className="mr-2 font-medium">Tempat Lahir</label>
              <span className="text-red-500">*</span>
            </div>
          }
          validateStatus={errors.tempatLahirAyah ? "error" : ""}
          help={errors.tempatLahirAyah ? errors.tempatLahirAyah.message : ""}
        >
          <Controller
            name="tempatLahirAyah"
            control={control}
            render={({ field }) => (
              <Input {...field} placeholder="Masukkan tempat lahir" />
            )}
          />
        </Form.Item>

        <Form.Item
          name="tglLahirAyah"
          label={
            <div className="flex items-center">
              <label className="mr-2 font-medium">Tanggal Lahir</label>
              <span className="text-red-500">*</span>
            </div>
          }
          validateStatus={errors.tglLahirAyah ? "error" : ""}
          help={errors.tglLahirAyah ? errors.tglLahirAyah.message : ""}
        >
          <Controller
            name="tglLahirAyah"
            control={control}
            render={({ field }) => (
              <Input type="date" {...field} placeholder="Pilih tanggal lahir" />
            )}
          />
        </Form.Item>

        <Form.Item
          name="job"
          label={
            <div className="flex items-center">
              <label className="mr-2 font-medium">Pekerjaan</label>
              <span className="text-red-500">*</span>
            </div>
          }
          validateStatus={errors.pekerjaanAyah ? "error" : ""}
          help={errors.pekerjaanAyah ? errors.pekerjaanAyah.message : ""}
        >
          <Controller
            name="pekerjaanAyah"
            control={control}
            render={({ field }) => (
              <Input {...field} placeholder="Masukkan pekerjaan" />
            )}
          />
        </Form.Item>

        <div className="font-bold text-xl mb-2 mt-5">Ibu</div>

        <Form.Item
          name="namaLengkap"
          label={
            <div className="flex items-center">
              <label className="mr-2 font-medium">Nama Lengkap</label>
              <span className="text-red-500">*</span>
            </div>
          }
          validateStatus={errors.namaLengkapIbu ? "error" : ""}
          help={errors.namaLengkapIbu ? errors.namaLengkapIbu.message : ""}
        >
          <Controller
            name="namaLengkapIbu"
            control={control}
            render={({ field }) => (
              <Input {...field} placeholder="Masukkan nama lengkap" />
            )}
          />
        </Form.Item>

        <Form.Item
          name="tempatLahir"
          label={
            <div className="flex items-center">
              <label className="mr-2 font-medium">Tempat Lahir</label>
              <span className="text-red-500">*</span>
            </div>
          }
          validateStatus={errors.tempatLahirIbu ? "error" : ""}
          help={errors.tempatLahirIbu ? errors.tempatLahirIbu.message : ""}
        >
          <Controller
            name="tempatLahirIbu"
            control={control}
            render={({ field }) => (
              <Input {...field} placeholder="Masukkan tempat lahir" />
            )}
          />
        </Form.Item>

        <Form.Item
          name="tglLahirIbu"
          label={
            <div className="flex items-center">
              <label className="mr-2 font-medium">Tanggal Lahir</label>
              <span className="text-red-500">*</span>
            </div>
          }
          validateStatus={errors.tglLahirIbu ? "error" : ""}
          help={errors.tglLahirIbu ? errors.tglLahirIbu.message : ""}
        >
          <Controller
            name="tglLahirIbu"
            control={control}
            render={({ field }) => (
              <Input type="date" {...field} placeholder="Pilih tanggal lahir" />
            )}
          />
        </Form.Item>

        <Form.Item
          name="job"
          label={
            <div className="flex items-center">
              <label className="mr-2 font-medium">Pekerjaan</label>
              <span className="text-red-500">*</span>
            </div>
          }
          validateStatus={errors.pekerjaanIbu ? "error" : ""}
          help={errors.pekerjaanIbu ? errors.pekerjaanIbu.message : ""}
        >
          <Controller
            name="pekerjaanIbu"
            control={control}
            render={({ field }) => (
              <Input {...field} placeholder="Masukkan pekerjaan" />
            )}
          />
        </Form.Item>

        <div className="font-bold text-l mb-2 mt-5">Anak Ke-1</div>

        <Form.Item
          name="namaLengkapAnak"
          label={
            <div className="flex items-center">
              <label className="mr-2 font-medium">Nama Lengkap</label>
              <span className="text-red-500">*</span>
            </div>
          }
          validateStatus={errors.namaLengkapAnak ? "error" : ""}
          help={errors.namaLengkapAnak ? errors.namaLengkapAnak.message : ""}
        >
          <Controller
            name="namaLengkapAnak"
            control={control}
            render={({ field }) => (
              <Input {...field} placeholder="Masukkan nama lengkap" />
            )}
          />
        </Form.Item>

        <Form.Item
          name="tempatLahirAnak"
          label={
            <div className="flex items-center">
              <label className="mr-2 font-medium">Tempat Lahir</label>
              <span className="text-red-500">*</span>
            </div>
          }
          validateStatus={errors.tempatLahirAnak ? "error" : ""}
          help={errors.tempatLahirAnak ? errors.tempatLahirAnak.message : ""}
        >
          <Controller
            name="tempatLahirAnak"
            control={control}
            render={({ field }) => (
              <Input {...field} placeholder="Masukkan tempat lahir" />
            )}
          />
        </Form.Item>

        <Form.Item
          name="tgglLahirAnak"
          label={
            <div className="flex items-center">
              <label className="mr-2 font-medium">Tanggal Lahir</label>
              <span className="text-red-500">*</span>
            </div>
          }
          validateStatus={errors.tgglLahirAnak ? "error" : ""}
          help={errors.tgglLahirAnak ? errors.tgglLahirAnak.message : ""}
        >
          <Controller
            name="tgglLahirAnak"
            control={control}
            render={({ field }) => (
              <Input type="date" {...field} placeholder="Pilih tanggal lahir" />
            )}
          />
        </Form.Item>

        <Form.Item
          name="jenisKelaminAnak"
          label={
            <div className="flex items-center">
              <label className="mr-2 font-medium">Jenis Kelamin</label>
              <span className="text-red-500">*</span>
            </div>
          }
          validateStatus={errors.jenisKelaminAnak ? "error" : ""}
          help={errors.jenisKelaminAnak ? errors.jenisKelaminAnak.message : ""}
        >
          <Controller
            name="jenisKelaminAnak"
            control={control}
            render={({ field }) => (
              <div className="flex">
                <label className="flex items-center space-x-2">
                  <input
                    type="radio"
                    {...field}
                    checked={field.value === "M"}
                    onChange={() => field.onChange("M")}
                    className="appearance-none border-2 border-[#948A54] rounded-full w-4 h-4 checked:bg-[#948A54] checked:border-[#948A54] inline-flex items-center justify-center"
                  />
                  <span>Pria</span>
                </label>
                <label className="flex items-center space-x-2 ml-5">
                  <input
                    type="radio"
                    {...field}
                    checked={field.value === "F"}
                    onChange={() => field.onChange("F")}
                    className="appearance-none border-2 border-[#948A54] rounded-full w-4 h-4 checked:bg-[#948A54] checked:border-[#948A54] inline-flex items-center justify-center"
                  />
                  <span>Wanita</span>
                </label>
              </div>
            )}
          />
        </Form.Item>

        <Form.Item
          name="pekerjaanAnak"
          label={
            <div className="flex items-center">
              <label className="mr-2 font-medium">Pekerjaan</label>
              <span className="text-red-500">*</span>
            </div>
          }
          validateStatus={errors.pekerjaanAnak ? "error" : ""}
          help={errors.pekerjaanAnak ? errors.pekerjaanAnak.message : ""}
        >
          <Controller
            name="pekerjaanAnak"
            control={control}
            render={({ field }) => (
              <Input {...field} placeholder="Masukkan pekerjaan" />
            )}
          />
        </Form.Item>

        <Form.Item>
          {fields.map((item, index) => (
            <div key={item.id} className="mb-2">
              <label className="font-bold text-xl mb-2 mt-5">
                Anak Ke-{index + 2}
              </label>

              <Form.Item
                name="namaLengkap"
                label={
                  <div className="flex items-center">
                    <label className="mr-2 font-medium">Nama Lengkap</label>
                    <span className="text-red-500">*</span>
                  </div>
                }
                validateStatus={
                  errors.child?.[index]?.namaLengkap ? "error" : ""
                }
                help={
                  errors.child?.[index]?.namaLengkap
                    ? errors.child[index]?.namaLengkap?.message
                    : ""
                }
              >
                <Controller
                  name={`child.${index}.namaLengkap`}
                  control={control}
                  render={({ field }) => (
                    <Input {...field} placeholder="Masukkan nama lengkap" />
                  )}
                />
              </Form.Item>

              <Form.Item
                name="tempatLahir"
                label={
                  <div className="flex items-center">
                    <label className="mr-2 font-medium">Tempat Lahir</label>
                    <span className="text-red-500">*</span>
                  </div>
                }
                validateStatus={
                  errors.child?.[index]?.tempatLahir ? "error" : ""
                }
                help={
                  errors.child?.[index]?.tempatLahir
                    ? errors.child[index]?.tempatLahir?.message
                    : ""
                }
              >
                <Controller
                  name={`child.${index}.tempatLahir`}
                  control={control}
                  render={({ field }) => (
                    <Input {...field} placeholder="Masukkan tempat lahir" />
                  )}
                />
              </Form.Item>

              <Form.Item
                name="tglLahir"
                label={
                  <div className="flex items-center">
                    <label className="mr-2 font-medium">Tanggal Lahir</label>
                    <span className="text-red-500">*</span>
                  </div>
                }
                validateStatus={errors.child?.[index]?.tgglLahir ? "error" : ""}
                help={
                  errors.child?.[index]?.tgglLahir
                    ? errors.child[index]?.tgglLahir?.message
                    : ""
                }
              >
                <Controller
                  name={`child.${index}.tgglLahir`}
                  control={control}
                  render={({ field }) => (
                    <Input
                      type="date"
                      {...field}
                      placeholder="Pilih tanggal lahir"
                    />
                  )}
                />
              </Form.Item>

              <Form.Item
                name="jenisKelamin"
                label={
                  <div className="flex items-center">
                    <label className="mr-2 font-medium">Jenis Kelamin</label>
                    <span className="text-red-500">*</span>
                  </div>
                }
                validateStatus={
                  errors.child?.[index]?.jenisKelamin ? "error" : ""
                }
                help={
                  errors.child?.[index]?.jenisKelamin
                    ? errors.child[index]?.jenisKelamin?.message
                    : ""
                }
              >
                <Controller
                  name={`child.${index}.jenisKelamin`}
                  control={control}
                  render={({ field }) => (
                    <div className="flex">
                      <label className="flex items-center space-x-2">
                        <input
                          type="radio"
                          {...field}
                          checked={field.value === "M"}
                          onChange={() => field.onChange("M")}
                          className="appearance-none border-2 border-[#948A54] rounded-full w-4 h-4 checked:bg-[#948A54] checked:border-[#948A54] inline-flex items-center justify-center"
                        />
                        <span>Pria</span>
                      </label>
                      <label className="flex items-center space-x-2 ml-5">
                        <input
                          type="radio"
                          {...field}
                          checked={field.value === "F"}
                          onChange={() => field.onChange("F")}
                          className="appearance-none border-2 border-[#948A54] rounded-full w-4 h-4 checked:bg-[#948A54] checked:border-[#948A54] inline-flex items-center justify-center"
                        />
                        <span>Wanita</span>
                      </label>
                    </div>
                  )}
                />
              </Form.Item>

              <Form.Item
                name="pekerjaan"
                label={
                  <div className="flex items-center">
                    <label className="mr-2 font-medium">Pekerjaan</label>
                    <span className="text-red-500">*</span>
                  </div>
                }
                validateStatus={errors.child?.[index]?.pekerjaan ? "error" : ""}
                help={
                  errors.child?.[index]?.pekerjaan
                    ? errors.child[index]?.pekerjaan?.message
                    : ""
                }
              >
                <Controller
                  name={`child.${index}.pekerjaan`}
                  control={control}
                  render={({ field }) => (
                    <Input {...field} placeholder="Masukkan pekerjaan" />
                  )}
                />
              </Form.Item>
            </div>
          ))}
          <div className="flex">
            {fields.length < 4 && (
              <button
                className={`text-white bg-[#948A54] border rounded-md mt-2 mr-3 ${fields.length === 4 ? "disabled" : "hover:bg-blue-500"
                  }`}
                type="button"
                disabled={fields.length === 4}
                onClick={() =>
                  append({
                    namaLengkap: "",
                    tempatLahir: "",
                    tgglLahir: "",
                    jenisKelamin: "",
                    pekerjaan: "",
                  })
                }
              >
                <div className="px-4 m-1"> Tambah Anak </div>
              </button>
            )}
            {fields.length >= 1 && (
              <button
                type="button"
                disabled={fields.length === 0}
                className="text-white bg-[#EF3E55] border rounded-md mt-2"
                onClick={() => {
                  if (fields.length >= 1) {
                    remove(fields.length - 1);
                  }
                }}
              >
                <div className="px-4 m-1"> Hapus Anak </div>
              </button>
            )}
          </div>
        </Form.Item>

        <Form.Item wrapperCol={{ offset: 0, span: 16 }}>
          <Button
            className="bg-[#948A54] mr-2"
            type="primary"
            onClick={() => onPrevPage()}
          >
            Kembali
          </Button>
          <Button
            className="bg-[#948A54] mr-2"
            type="primary"
            htmlType="submit"
          >
            Lanjutkan
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};
export default BiologicalFamilyForm;
