import { Button, Checkbox, Divider, Form, Upload } from "antd";
import { useEffect, useState } from "react";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { Controller, useForm } from "react-hook-form";
import { HeaderForm } from "./HeaderForm";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import PopUp from "./PopUp";
import { UploadChangeParam } from "antd/es/upload";
import { applyFormDataType } from "./ApplyForm";
import { mainFamilyFormDataType } from "./MainFamilyForm";
import { personalFormDataType } from "./PersonalDataForm";
import { biologicalFamilyFormDataType } from "./BiologicalFamilyForm";
import { workHistoryFormDataType } from "./WorkHistoryForm";
import { skillFormDataType } from "./SkillForm";
import { bankInfoFormDataType } from "./BankInfoForm";
import { BackendApiUrl } from "@/functions/BackendApiUrl";
import { v4 as uuidv4 } from "uuid";
import { format } from "date-fns";
import { id } from "date-fns/locale";
import PopUpSuccess from "./PopUpSuccess";

const FormSchema = z.object({
  photo: z.any(),
  check: z.boolean(),
});

interface newMainForm {
  name: string;
  birthPlace: string;
  mainFamilyStatusId: number;
  work: string;
  gender: string;
  birthdate: string;
}

interface newBiologicalForm {
  fullname: string;
  birthPlace: string;
  birthdate: string;
  gender: string;
  work: string;
  biologicalFamilyStatusId: number;
}

interface newWorkForm {
  companyName: string;
  position: string;
  yearIn: string;
  yearOut: string;
  referenceName: string;
  referenceContact: string;
}

interface newCertifForm {
  certificationName: string;
  institutionName: string;
  year: number;
}

export type photoFormDataType = z.infer<typeof FormSchema>;

export interface PhotoFormCustomDataType {
  photo: {
    base64: string | ArrayBuffer;
    file?: File;
    url: string;
    name: string;
    size: number;
    type: string;
    errorMessage?: string;
  };
}

const PhotoForm: React.FC<{
  onSubmit: (data: photoFormDataType) => void;
  applyForm?: applyFormDataType;
  personalDataForm?: personalFormDataType;
  biologicalFamilyForm?: biologicalFamilyFormDataType;
  workHistoryForm?: workHistoryFormDataType;
  skillForm?: skillFormDataType;
  mainFamilyForm?: mainFamilyFormDataType;
  bankInfoForm?: bankInfoFormDataType;
  photoForm?: photoFormDataType;
  onNextPage: () => void;
  onPrevPage: (data: photoFormDataType) => void;
}> = ({
  onSubmit,
  photoForm,
  onPrevPage,
  onNextPage,
  applyForm,
  mainFamilyForm,
  personalDataForm,
  biologicalFamilyForm,
  bankInfoForm,
  skillForm,
  workHistoryForm,
}) => {
    const { handleSubmit, setValue, control, watch } = useForm<photoFormDataType>(
      {
        resolver: zodResolver(FormSchema),
      }
    );
    const tempCheck = watch("check");

    const [isModalVisible, setIsModalVisible] = useState(false);

    const [myForm, setMyForm] = useState<PhotoFormCustomDataType>({
      photo: {
        name: "",
        base64: "",
        size: 0,
        type: "",
        url: "",
      },
    });

    const [photoId, setPhotoId] = useState<string>("");
    useEffect(() => {
      if (photoForm) {
        setValue("check", photoForm.check);
      }
    }, [photoForm, setValue]);

    const [firstVisit, setFirstVisit] = useState(true);

    useEffect(() => {
      if (firstVisit) {
        // Jika ini adalah kunjungan pertama, arahkan ke atas halaman
        window.scrollTo(0, 0);

        // Setel status kunjungan pertama menjadi false
        setFirstVisit(false);
      }
    }, [firstVisit]);

    function onHandleFormSubmit(data: photoFormDataType) {
      onSubmit(data);
    }

    function validateForm() {
      console.log("dilanjutkan ", myForm);
      if (myForm.photo.errorMessage) {
        return;
      }
      // Hitting submit without fill in all the forms before
      let photoErrorMsg = "";
      // let checkErrorMsg = ''

      if (!myForm.photo.file) {
        photoErrorMsg += "Anda harus mengunggah gambar";
      }

      if (!photoErrorMsg) {
        showModal();
        return;
      }

      setMyForm((prev) => {
        return {
          ...prev,
          photo: {
            ...prev.photo,
            errorMessage: photoErrorMsg,
          },
        };
      });
    }

    function onPhotoChange(e: UploadChangeParam) {
      const uuid = uuidv4();
      setPhotoId(uuid);

      if (e.fileList.length <= 0) {
        setMyForm((prev) => {
          return {
            ...prev,
            photo: {
              ...prev.photo,
              errorMessage: "Anda harus mengunggah gambar",
            },
          };
        });
        return;
      }

      const name: string = e.fileList[0]!.name!;

      // Get URL
      const url = window.URL.createObjectURL(e.fileList[0]!.originFileObj!);

      // Get Base64
      const reader = new FileReader();
      let base64: string | ArrayBuffer = "";
      reader.onload = (e) => {
        base64 = e.target!.result!;
      };
      reader.readAsDataURL(e.fileList[0]!.originFileObj!);

      // File
      const myFile: File = e.fileList[0]!.originFileObj!;

      let errorMsg = "";

      // File type validation
      const type: string = e.fileList[0]!.type!;
      if (type && type != "image/jpeg") {
        errorMsg += "Jenis gambar harus berupa JPEG or JPG";
      }

      // File size validation
      const size: number = e.fileList[0]!.size!;
      // Not valid if size is larger than 5 MB
      if (size > 5 * 1024 * 1024) {
        if (errorMsg) {
          errorMsg += " dan ukuran gambar maksimum 5 MB";
        } else {
          errorMsg += "Ukuran gambar maksimum 5 MB";
        }
      }

      if (errorMsg) {
        setMyForm((prev) => {
          return {
            ...prev,
            photo: {
              ...prev.photo,
              errorMessage: errorMsg,
            },
          };
        });
        return;
      }

      setMyForm((prev) => {
        return {
          ...prev,
          photo: {
            name: name,
            type: type,
            size: size,
            url: url,
            base64: base64,
            file: myFile,
            errorMessage: "",
          },
        };
      });
    }

    function onCheckChanged(e: CheckboxChangeEvent) {
      const isChecked = e.target.checked;
      setValue("check", isChecked);
    }

    // Fungsi untuk menampilkan modal
    const showModal = () => {
      setIsModalVisible(true);
    };

    // Fungsi untuk menyembunyikan modal
    const handleCancel = () => {
      setIsModalVisible(false);
    };

    const [showPopUpSuccess, setShowPopUpSuccess] = useState(false);

    const handleOnSubmit = async () => {
      const ext = myForm?.photo.name.split(".")[1];
      const presignedUrl = await fetch(
        BackendApiUrl.uploadMinio + `?fileName=${photoId}.${ext}`
      );
      const responseUrl = await presignedUrl.json();
      console.log(responseUrl.data);
      await fetch(responseUrl.data, {
        method: "PUT",
        body: myForm.photo.file,
      });

      const newMainFamilyForm: newMainForm[] = [];
      if (mainFamilyForm != null) {
        if (
          mainFamilyForm.namaLengkap !== "-" &&
          mainFamilyForm.tempatLahir !== "-" &&
          mainFamilyForm.namaLengkap !== "-" &&
          mainFamilyForm.tglLahir !== ""
        ) {
          const main = {
            name: mainFamilyForm.namaLengkap,
            birthPlace: mainFamilyForm.tempatLahir,
            mainFamilyStatusId: parseInt(mainFamilyForm.hubungan, 10),
            work: mainFamilyForm.pekerjaan,
            gender: parseInt(mainFamilyForm.hubungan, 10) === 3 ? 'F' : 'M',
            birthdate: mainFamilyForm.tglLahir,
          };
          newMainFamilyForm.push(main);
        }

        if (mainFamilyForm.child && mainFamilyForm.child.length > 0) {
          mainFamilyForm.child.map((value, index) => {
            const items = {
              name: value.namaLengkap,
              birthPlace: value.tempatLahir,
              mainFamilyStatusId: index + 5,
              work: value.pekerjaan,
              gender: value.jenisKelamin,
              birthdate: value.tgglLahir,
            };
            newMainFamilyForm.push(items);
          });
        }
      }

      const newBiologicalFamilyForm: newBiologicalForm[] = [];
      if (biologicalFamilyForm != null) {
        const biologicalAyah = {
          fullname: biologicalFamilyForm.namaLengkapAyah,
          birthPlace: biologicalFamilyForm.tempatLahirAyah,
          birthdate: biologicalFamilyForm.tglLahirAyah === "" ? "0001-01-01" : biologicalFamilyForm.tglLahirAyah,
          work: biologicalFamilyForm.pekerjaanAyah,
          gender: "M",
          biologicalFamilyStatusId: 1,
        };
        const biologicalIbu = {
          fullname: biologicalFamilyForm.namaLengkapIbu,
          birthPlace: biologicalFamilyForm.tempatLahirIbu,
          birthdate: biologicalFamilyForm.tglLahirIbu === "" ? "0001-01-01" : biologicalFamilyForm.tglLahirIbu,
          work: biologicalFamilyForm.pekerjaanIbu,
          gender: "F",
          biologicalFamilyStatusId: 2,
        };
        const biologicalAnak = {
          fullname: biologicalFamilyForm.namaLengkapAnak,
          birthPlace: biologicalFamilyForm.tempatLahirAnak,
          birthdate: biologicalFamilyForm.tgglLahirAnak === "" ? "0001-01-01" : biologicalFamilyForm.tgglLahirAnak,
          work: biologicalFamilyForm.pekerjaanAnak,
          gender: biologicalFamilyForm.jenisKelaminAnak,
          biologicalFamilyStatusId: 10,
        };
        newBiologicalFamilyForm.push(biologicalAyah);
        newBiologicalFamilyForm.push(biologicalIbu);
        newBiologicalFamilyForm.push(biologicalAnak);
        if (biologicalFamilyForm.child.length !== 0) {
          biologicalFamilyForm.child.map((value, index) => {
            const items = {
              fullname: value.namaLengkap,
              birthPlace: value.tempatLahir,
              birthdate: value.tgglLahir,
              gender: value.jenisKelamin,
              work: value.pekerjaan,
              biologicalFamilyStatusId: index + 11,
            };
            newBiologicalFamilyForm.push(items);
          });
        }
      }

      const newWorkHistoryForm: newWorkForm[] = [];

      if (workHistoryForm != null) {
        const work = {
          companyName: workHistoryForm.namaPerusahaan,
          position: workHistoryForm.posisi,
          yearIn: workHistoryForm.tahunMasuk,
          yearOut: workHistoryForm.tahunKeluar,
          referenceName: workHistoryForm.namaReferensi,
          referenceContact: workHistoryForm.kontakReferensi,
        };
        newWorkHistoryForm.push(work);

        if (workHistoryForm.childCompany.length !== 0) {
          workHistoryForm.childCompany.map((value) => {
            const items = {
              companyName: value.namaPerusahaan,
              position: value.posisi,
              yearIn: value.tahunMasuk,
              yearOut: value.tahunKeluar,
              referenceName: value.namaReferensi,
              referenceContact: value.kontakReferensi,
            };
            newWorkHistoryForm.push(items);
          });
        }
      }

      const newCertifForm: newCertifForm[] = [];
      if (skillForm != null) {
        const certif = {
          certificationName: skillForm.namaPelatihan,
          institutionName: skillForm.namaLembaga,
          year: skillForm.tahun === "" ? 0 : parseInt(skillForm.tahun),
        };
        newCertifForm.push(certif);
        if (skillForm.childSkill.length !== 0) {
          skillForm.childSkill.map((value) => {
            const items = {
              certificationName: value.namaPelatihan,
              institutionName: value.namaLembaga,
              year: value.tahun === "" ? 0 : parseInt(value.tahun),
            };
            newCertifForm.push(items);
          });
        }
      }

      const body = {
        applicationForm: {
          requestPosition: applyForm?.position === "Lainnya" ? applyForm.otherPosition : applyForm?.position,
          careerPurpose: applyForm?.carrerGoals,
          applyingReason: applyForm?.reasonApply,
          previousContractTypeId: applyForm?.statusPrev,
          salaryTypeId: applyForm?.incomeStatus,
          currentSalary: applyForm?.income,
          requestSalary: applyForm?.expectedIncome,
          startWorkDate: applyForm?.startWork === "1" ? "Secepatnya" : applyForm?.startWorkDate,
        },
        candidate: {
          fullName: personalDataForm?.name,
          ktp: personalDataForm?.noKtp,
          npwp: personalDataForm?.npwpStatus === "1" ? "0000000000000000" : personalDataForm?.noNpwp,
          gender: personalDataForm?.gender === "1" ? "M" : "F",
          isBpjsKesehatan:
            personalDataForm?.statusBpjsKesehatan === "1" ? true : false,
          isBpjsKetenagakerjaan:
            personalDataForm?.statusBpjsKetenagakerjaan === "1" ? true : false,
          educationTitle: personalDataForm?.title,
          lastGpa: personalDataForm?.gpa,
          bloodType: personalDataForm?.bloodType,
          birthPlace: personalDataForm?.tempatLahir,
          birthdate: personalDataForm?.tglLahir,
          lastEducation: personalDataForm?.education,
          lastEducationYearIn: personalDataForm?.tahunMasukPendidikan,
          lastEducationYearOut: personalDataForm?.tahunKeluarPendidikan,
          ktpAddress: personalDataForm?.alamatKtp,
          currentAddress: personalDataForm?.alamatDomisili,
          homePhonenumber: personalDataForm?.noTel,
          mobilePhonenumber: personalDataForm?.noHp,
          emergencyRelativeName: personalDataForm?.namaKontakDarurat,
          emergencyRelativeRelation: personalDataForm?.hubunganKontakDarurat,
          emergencyRelativePhonenumber: personalDataForm?.noKontakDarurat,
          email: personalDataForm?.alamatEmail,
          religionId: personalDataForm?.agama,
          numberOfSiblings: personalDataForm?.jumlahSaudara.toString(),
          maritalStatusId: personalDataForm?.statusPernikahan,
          numberOfChildrens: personalDataForm?.noSaudara,
          nationality: personalDataForm?.kewarganegaraan,
          socialMedia: personalDataForm?.medSosStatus === "1" ? "-" : personalDataForm?.medSos + "-" + personalDataForm?.medSosUsername,
          diasesHistory: personalDataForm?.riwayatPenyakit,
          photo: {
            fileId: photoId,
            fileName: myForm.photo.name,
            contentType: myForm.photo.type,
          },
        },
        biologicalFamilies: newBiologicalFamilyForm,
        mainFamilies: newMainFamilyForm,
        workExperiences: newWorkHistoryForm,
        skill: skillForm?.skillDesc,
        certifications: newCertifForm,
        cimbAccount: {
          accountName: bankInfoForm?.accountOwner,
          accountNumber: bankInfoForm?.accountNumber,
          bankId: bankInfoForm?.bankId,
          branch: bankInfoForm?.branch,
          otherBankName: bankInfoForm?.otherBankName === "-" ? null : bankInfoForm?.otherBankName,
        },
      };
      const responseData = await fetch(BackendApiUrl.postCandidate, {
        method: "POST",
        body: JSON.stringify(body),
        headers: {
          "Content-Type": "application/json",
        },
      });
      console.log(responseData);

      if (responseData.status === 200) {
        setShowPopUpSuccess(true);
        localStorage.removeItem("applyForm");
        localStorage.removeItem("personalDataForm");
        localStorage.removeItem("mainFamilyForm");
        localStorage.removeItem("biologicalFamilyForm");
        localStorage.removeItem("workHistoryForm");
        localStorage.removeItem("skillForm");
        localStorage.removeItem("bankInfoForm");
        localStorage.removeItem("photoForm");
      }
    };

    const closePopUpSuccess = () => {
      // Close PopUpSuccess
      setShowPopUpSuccess(false);
    };

    return (
      <>
        <div className="flex justify-center items-center">
          <div className="w-full py-4 max-w-1/2">
            <HeaderForm steps={4} />
            <Form layout="vertical" onFinish={handleSubmit(onHandleFormSubmit)}>
              <Form.Item
                name="photo"
                label={
                  <div className="flex items-center">
                    <label className="mr-2 font-medium">Pas Foto (3X4)</label>
                  </div>
                }
              >
                <div className="max-w-xs">
                  <Upload
                    onChange={onPhotoChange}
                    beforeUpload={() => false}
                    listType="picture"
                    maxCount={1}
                  >
                    <Button>Pilih file</Button>
                  </Upload>
                </div>
              </Form.Item>
              <div className="text-red-500">
                {myForm?.photo.errorMessage ?? ""}
              </div>

              <Form.Item
                name="check"
                label={
                  <div className="text-red-500">
                    Format File : JPG, JPEG *max 5MB
                  </div>
                }
              >
                <Controller
                  name="check"
                  control={control}
                  render={({ field: { value } }) => (
                    <Checkbox
                      onChange={onCheckChanged} // send value to hook form
                      checked={value}
                    >
                      Dengan ini, Saya dengan sungguh-sungguh dan penuh kesadaran
                      menyatakan bahwa semua informasi yang Saya cantumkan sebagai
                      persyaratan proses rekrutmen PT. Accelist Lentera Indonesia
                      adalah akurat dan benar. Saya menyadari bahwa apabila dikemudian
                      hari terungkap bahwa informasi tersebut tidak benar, Saya bersedia
                      menerima konsekuensi yang berlaku.
                    </Checkbox>
                  )}
                ></Controller>
              </Form.Item>
              <div className="text-[#262931] text-xs">
                Tanggal: {format(new Date(), "d MMMM yyyy", { locale: id })}
              </div>
              <Divider />
              <Form.Item wrapperCol={{ offset: 0, span: 16 }}>
                <Button
                  className="bg-[#948A54] mr-2"
                  type="primary"
                  onClick={() => onPrevPage({ check: tempCheck })}
                >
                  Kembali
                </Button>
                <Button
                  className="bg-[#948A54] mr-2"
                  type="primary"
                  htmlType="submit"
                  onClick={validateForm}
                  disabled={!tempCheck}
                >
                  Lanjutkan
                </Button>
                <PopUp
                  visible={isModalVisible}
                  onCancel={handleCancel}
                  onSubmit={handleOnSubmit}
                  onFirstPage={onNextPage}
                />
                <PopUpSuccess
                  onClose={closePopUpSuccess}
                  onFirstPage={onNextPage}
                  isVisible={showPopUpSuccess}
                >
                  <p className="text-lg text-center mt-5">
                    Form berhasil dikumpulkan, tunggu sampai informasi selanjutnya
                    dari staff kami. Terima kasih
                  </p>
                </PopUpSuccess>
              </Form.Item>
            </Form>
          </div>
        </div>
      </>
    );
  };
export default PhotoForm;
