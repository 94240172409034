// Request will be proxied via /api/be/[...apiGateway].ts
const baseUrl = '/api/be';

export const BackendApiUrl = {
    test: baseUrl + '/api/test',
    
    getAppliedPosition: baseUrl + '/api/v1/dropdown/appliedposition',
    addAppliedPosition: baseUrl + '/api/v1/appliedposition/add',
    editAppliedPosition: baseUrl + '/api/v1/appliedposition/edit',
    deleteAppliedPosition: baseUrl + '/api/v1/appliedposition/delete',
    getCurrentSalary: baseUrl + '/api/v1/CurrentSalary/get-current-salary',
    getReligion: baseUrl + '/api/v1/Religions/religions',
    postCandidate: baseUrl + '/api/v1/candidate-application-form/create',
    postPhoto: baseUrl + '/api/v1/blob/upload',
    uploadMinio: baseUrl + '/api/v1/blob/presigned-put-object',
    uploadFileMetaData: baseUrl + '/api/v1/blob/blob-information',
    getCandidateStatuses: baseUrl + '/api/v1/candidate/get-candidate-statuses',
    getCandidateList: baseUrl + '/api/v1/candidate/candidates',
    getCandidateStatusHistory: baseUrl + '/api/v1/candidate/get-candidate-status-history',
    postCandidateStatus: baseUrl + '/api/v1/candidate/update-candidate-status',
    getCandidateDetail: baseUrl + '/api/v1/candidate/detail',
    getCandidateImage: baseUrl + '/api/v1/candidate/image',
    getSocialMedia: baseUrl + '/api/v1/dropdown/socialmedia',
    getDegrees: baseUrl + '/api/v1/dropdown/degrees',
    getCandidatePosition: baseUrl + '/api/v1/dropdown/appliedposition',
    getStatus: baseUrl + '/api/v1/dropdown/status',
    getBankAccount: baseUrl + '/api/v1/dropdown/banks',
}

export function GetCandidateList(
    page: number | undefined,
    itemPerPage: number | undefined,
    name: string| undefined,
    status: number | undefined,
    position: string | undefined,
    dateFrom: string | undefined,
    dateTo: string | undefined
) {
    const param = new URLSearchParams();
    if (page && itemPerPage) {
        param.append('page', page.toString());
        param.append('itemPerPage', itemPerPage.toString());
        
    }

    if(name !== undefined && name !== '' && name !== null){
        param.append('name', name);
    }

    if(status !== undefined && status !== 0 && status !== null){
        param.append('status', status.toString());
    }

    if(position !== undefined && position !== '' && position !== null){
        param.append('position', position);
    }

    if(dateFrom !== undefined && dateTo !== undefined && dateTo !== null && dateFrom !== null){
        param.append('dateFrom', dateFrom.toString());
        param.append('dateTo', dateTo.toString());
    }
    return BackendApiUrl.getCandidateList + '?' + param.toString();
}

