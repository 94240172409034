import { Form, Input, Button } from "antd";
import React, { useEffect, useState } from "react";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { HeaderForm } from "./HeaderForm";

const FormSchema = z.object({
  skillDesc: z.string({
    required_error: 'Skill tidak boleh kosong'
  }).nonempty({
    message: "Skill tidak boleh kosong",
  }).refine((value) => {
    const wordCount = value.split(/\s+/).length;
    return wordCount <= 250;
  }, {
    message: "Skill maksimal 250 kata",
  }).refine(value => !/^\s*$/.test(value), {
    message: 'Tidak boleh hanya berisi spasi',
  }),
  namaPelatihan: z.string().max(255, {
    message: "Nama pelatihan harus kurang dari 255 karakter",
  }),
  namaLembaga: z.string().max(255, {
    message: "Nama lembaga harus kurang dari 255 karakter",
  }),
  tahun: z.string()
    .max(255, {
      message: 'Tahun harus kurang dari 255 karakter'
    }).refine(value => /^[0-9-]*$/.test(value), {
      message: 'Tahun harus berupa angka'
    }),
  childSkill: z
    .array(
      z.object({
        namaPelatihan: z
          .string()
          .max(255, {
            message: "Nama pelatihan harus kurang dari 255 karakter",
          }),
        namaLembaga: z
          .string()
          .max(255, {
            message: "Nama lembaga harus kurang dari 255 karakter",
          }),
        tahun: z.string().max(255, {
          message: 'Tahun harus kurang dari 255 karakter'
        }).refine(value => /^[0-9]*$/.test(value), {
          message: 'Tahun harus berupa angka'
        }),
      })
    )
    .min(0),
});

export type skillFormDataType = z.infer<typeof FormSchema>;
const { TextArea } = Input;

const SkillForm: React.FC<{
  onSubmit: (data: skillFormDataType) => void;
  skillForm?: skillFormDataType;
  onNextPage: () => void;
  onPrevPage: () => void;
}> = ({ onSubmit, skillForm, onNextPage, onPrevPage }) => {
  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
    watch
  } = useForm<skillFormDataType>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      namaPelatihan: "",
      namaLembaga: "",
      tahun: ""
    }
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "childSkill",
  });

  useEffect(() => {
    if (skillForm) {
      setValue("skillDesc", skillForm.skillDesc);
      setValue("namaPelatihan", skillForm.namaPelatihan);
      setValue("namaLembaga", skillForm.namaLembaga);
      setValue("tahun", skillForm.tahun);
      setValue("childSkill", skillForm.childSkill)
    }
  }, [skillForm, setValue]);

  const [firstVisit, setFirstVisit] = useState(true);

  useEffect(() => {
    if (firstVisit) {
      // Jika ini adalah kunjungan pertama, arahkan ke atas halaman
      window.scrollTo(0, 0);

      // Setel status kunjungan pertama menjadi false
      setFirstVisit(false);
    }
  }, [firstVisit]);

  function onHandleFormSubmit(data: skillFormDataType) {
    onSubmit(data);
    onNextPage();
  }
  const [skillText, setSkillText] = useState('');

  const handleTextChange = (e, field, setTextFunction) => {
    const inputText = e.target.value;
    const wordCount = inputText.split(/\s+/).length;

    if (wordCount <= 250) {
      setTextFunction(inputText);
      field.onChange(e);
    }
  };
  return (
    <>
      <HeaderForm steps={2} />
      <Form layout="vertical" onFinish={handleSubmit(onHandleFormSubmit)}>
        <div className="font-bold text-xl mb-2 mt-5">
          E. SKILL/TOOLS YANG DIKUASAI
          <span className="text-red-500"> *</span>
        </div>
        <Form.Item
          name="skillDesc"
          validateStatus={errors.skillDesc ? "error" : ""}
          help={errors.skillDesc ? errors.skillDesc.message : ""}
        >
          <Controller
            name="skillDesc"
            control={control}
            render={({ field }) => (
              <div style={{ position: 'relative' }}>
                <TextArea
                  {...field}
                  rows={4}
                  placeholder="Maximal 250 kata"
                  value={watch("skillDesc")}
                  onChange={(e) => handleTextChange(e, field, setSkillText)}
                />
                <div
                  style={{
                    position: 'absolute',
                    bottom: 8,
                    right: 8,
                    color: skillText.split(/\s+/).filter((word) => word).length > 250 ? 'red' : 'gray',
                  }}
                >
                  {skillText.split(/\s+/).filter((word) => word).length}/250
                </div>
              </div>
            )}
          />
        </Form.Item>

        <div className="font-bold text-xl mb-2 mt-5">
          F. PELATIHAN/SERTIFIKASI (5 Terpenting)
        </div>
        <div className="font-bold text-l mb-3 mt-5">
          PELATIHAN/SERTIFIKASI KE-1
        </div>

        <Form.Item
          name="position"
          label={
            <div className="flex items-center">
              <label className="mr-2 font-medium">Nama Pelatihan/Sertifikasi</label>
            </div>
          }
          validateStatus={errors.namaPelatihan ? "error" : ""}
          help={errors.namaPelatihan ? errors.namaPelatihan.message : ""}
        >
          <div className="flex flex-col">
            <Controller
              name="namaPelatihan"
              control={control}
              render={({ field }) => (
                <Input {...field} placeholder="Masukkan nama pelatihan/sertifikasi" />
              )}
            />
          </div>
        </Form.Item>

        <Form.Item
          name="namaLembaga"
          label={
            <div className="flex items-center">
              <label className="mr-2 font-medium">Nama Lembaga</label>
            </div>
          }
          validateStatus={errors.namaLembaga ? "error" : ""}
          help={errors.namaLembaga ? errors.namaLembaga.message : ""}
        >
          <div className="flex flex-col">
            <Controller
              name="namaLembaga"
              control={control}
              render={({ field }) => (
                <Input {...field} placeholder="Masukkan nama lembaga" />
              )}
            />
          </div>
        </Form.Item>

        <Form.Item
          name="tahun"
          label={
            <div className="flex items-center">
              <label className="mr-2 font-medium">Tahun</label>
            </div>
          }
          validateStatus={errors.tahun ? "error" : ""}
          help={errors.tahun ? errors.tahun.message : ""}
        >
          <div className="flex flex-col">
            <Controller
              name="tahun"
              control={control}
              render={({ field }) => (
                <Input {...field} placeholder="Masukkan tahun pelatihan/sertifikasi" />
              )}
            />
          </div>
        </Form.Item>

        <Form.Item>
          {fields.map((item, index) => (
            <div key={item.id} className="mb-5">
              <label className="font-bold text-xl mb-2 mt-5">
                PELATIHAN/SETRIFIKASI KE-{index + 2}
              </label>

              <Form.Item
                name="namaPelatihan"
                label={
                  <div className="flex items-center">
                    <label className="mr-2 mt-2 font-medium">Nama Pelatihan/Sertifikasi</label>
                  </div>
                }
                validateStatus={errors.childSkill?.[index]?.namaPelatihan ? "error" : ""}
                help={errors.childSkill?.[index]?.namaPelatihan ? errors.childSkill[index]?.namaPelatihan?.message : ""}
              >
                <Controller
                  name={`childSkill.${index}.namaPelatihan`}
                  control={control}
                  render={({ field }) => (
                    <Input {...field} placeholder="Masukkan nama pelatihan/sertifikasi" />
                  )}
                />
              </Form.Item>

              <Form.Item
                name="lembaga"
                label={
                  <div className="flex items-center">
                    <label className="mr-2 font-medium">Nama Lembaga</label>
                  </div>
                }
                validateStatus={errors.childSkill?.[index]?.namaLembaga ? "error" : ""}
                help={errors.childSkill?.[index]?.namaLembaga ? errors.childSkill[index]?.namaLembaga?.message : ""}
              >
                <Controller
                  name={`childSkill.${index}.namaLembaga`}
                  control={control}
                  render={({ field }) => (
                    <Input {...field} placeholder="Masukkan nama lembaga" />
                  )}
                />
              </Form.Item>

              <Form.Item
                name="tahun"
                label={
                  <div className="flex items-center">
                    <label className="mr-2 font-medium">Tahun</label>
                  </div>
                }
                validateStatus={errors.childSkill?.[index]?.tahun ? "error" : ""}
                help={errors.childSkill?.[index]?.tahun ? errors.childSkill[index]?.tahun?.message : ""}
              >
                <div className="flex flex-col">
                  <Controller
                    name={`childSkill.${index}.tahun`}
                    control={control}
                    render={({ field }) => (
                      <Input {...field} placeholder="Masukkan tahun pelatihan/sertifikasi" />
                    )}
                  />
                </div>
              </Form.Item>
            </div>
          ))}
          <div className="flex">
            {fields.length < 4 && (
              <button
                className={`text-white bg-[#948A54] border rounded-md mt-2 mr-3 ${fields.length === 4 ? "disabled" : "hover:bg-blue-500"}`}
                type="button"
                disabled={fields.length === 4}
                onClick={() =>
                  append({
                    namaPelatihan: "",
                    namaLembaga: "",
                    tahun: "",
                  })
                }
              >
                <div className='px-4 py-2'>Tambah Pelatihan/Sertifikasi</div>
              </button>
            )}
            {fields.length >= 1 && (
              <button
                type="button"
                disabled={fields.length === 0}
                className="text-white bg-[#EF3E55] border rounded-md mt-2"
                onClick={() => {
                  if (fields.length >= 1) {
                    remove(fields.length - 1);
                  }
                }}
              >
                <div className='px-4 py-2'>Hapus Pelatihan/Sertifikasi</div>
              </button>
            )}
          </div>
        </Form.Item>

        <Form.Item wrapperCol={{ offset: 0, span: 16 }}>
          <Button
            className="bg-[#948A54] mr-2"
            type="primary"
            onClick={() => onPrevPage()}
          >
            Kembali
          </Button>
          <Button className="bg-[#948A54]" type="primary" htmlType="submit">
            Lanjutkan
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};
export default SkillForm;
