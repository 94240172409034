import { Form, Input, Button, Select } from "antd";
import React, { useEffect, useState } from "react";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm, Controller } from "react-hook-form";
import { HeaderForm } from "./HeaderForm";
import useSWR from "swr";
import { BackendApiUrl } from "@/functions/BackendApiUrl";
import { useSwrFetcherWithAccessToken } from "@/functions/useSwrFetcherWithAccessToken";
import { SelectOption, Dropdown } from "@/interfaces/SelectOption";

const FormSchema = z.object({
  accountOwner: z
    .string({
      required_error: "Nama pemegang rekening tidak boleh kosong",
    })
    .nonempty({
      message: "Nama pemegang rekening tidak boleh kosong",
    })
    .max(255, {
      message: "Nama pemegang rekening harus kurang dari 255 karakter",
    })
    .refine((value) => !/^\s*$/.test(value), {
      message: "Tidak boleh hanya berisi spasi",
    }),
  accountNumber: z
    .string({
      required_error: "Nomor rekening tidak boleh kosong",
    })
    .nonempty({
      message: "Nomor rekening tidak boleh kosong",
    })
    .max(20, {
      message: "Nomor rekening harus kurang dari 20 digit",
    })
    .refine((value) => !/^\s*$/.test(value), {
      message: "Tidak boleh hanya berisi spasi",
    })
    .refine((value) => /^[0-9]*$/.test(value), {
      message: "Nomor rekening harus berupa angka",
    }),
  bankId: z
    .number({
      required_error: "Nama bank tidak boleh kosong",
    }),
  otherBankName: z
    .string({
      required_error: "Nama bank tidak boleh kosong",
    })
    .nonempty({
      message: "Nama bank tidak boleh kosong",
    })
    .max(30, {
      message: "Nama bank harus kurang dari 30 karakter",
    })
    .refine((value) => !/^\s*$/.test(value), {
      message: "Tidak boleh hanya berisi spasi",
    }),
  branch: z
    .string({
      required_error: "Nama cabang tidak boleh kosong",
    })
    .nonempty({
      message: "Cabang tidak boleh kosong",
    })
    .max(255, {
      message: "Cabang harus kurang dari 255 karakter",
    })
    .refine((value) => !/^\s*$/.test(value), {
      message: "Tidak boleh hanya berisi spasi",
    }),
});

export type bankInfoFormDataType = z.infer<typeof FormSchema>;

const SkillForm: React.FC<{
  onSubmit: (data: bankInfoFormDataType) => void;
  bankInfoForm?: bankInfoFormDataType;
  onNextPage: () => void;
  onPrevPage: () => void;
}> = ({ onSubmit, bankInfoForm, onNextPage, onPrevPage }) => {
  const {
    handleSubmit,
    control,
    setValue,
    watch,
    formState: { errors },
  } = useForm<bankInfoFormDataType>({
    resolver: zodResolver(FormSchema),
  });

  useEffect(() => {
    if (bankInfoForm) {
      setValue("accountOwner", bankInfoForm.accountOwner);
      setValue("accountNumber", bankInfoForm.accountNumber);
      setValue("bankId", bankInfoForm.bankId);
      setValue("branch", bankInfoForm.branch);
      setValue("otherBankName", bankInfoForm.otherBankName);
    }
  }, [bankInfoForm, setValue]);

  const [firstVisit, setFirstVisit] = useState(true);

  const swrFetcher = useSwrFetcherWithAccessToken();

  useEffect(() => {
    if (firstVisit) {
      // Jika ini adalah kunjungan pertama, arahkan ke atas halaman
      window.scrollTo(0, 0);

      // Setel status kunjungan pertama menjadi false
      setFirstVisit(false);
    }
  }, [firstVisit]);

  function onHandleFormSubmit(data: bankInfoFormDataType) {
    onSubmit(data);
    onNextPage();
  }

  const { data: bankId } = useSWR<SelectOption<number>[]>(
    BackendApiUrl.getBankAccount,
    swrFetcher
  );

  function bankNames(): Dropdown<number>[] {
    if (!bankId) {
      return [];
    }

    const combinedBanks = [...bankId];

    return combinedBanks.map((item) => {
      const row: Dropdown<number> = {
        label: item.name,
        value: item.id,
      };
      return row;
    });
  }

  useEffect(() => {
    if (watch("bankId") !== 5) {
      setValue("otherBankName", "-");
    } else if (
      watch("bankId") === 5 &&
      watch("otherBankName") === "-"
    ) {
      setValue("otherBankName", "");
    } else if (
      bankInfoForm &&
      watch("bankId") === 5 &&
      watch("otherBankName") !== "-"
    ) {
      setValue("otherBankName", bankInfoForm.otherBankName);
    }
  }, [watch("bankId")]);

  return (
    <>
      <HeaderForm steps={3} />
      <Form layout="vertical" onFinish={handleSubmit(onHandleFormSubmit)}>
        <div className="font-bold text-xl mb-2 mt-5">
          G. DATA REKENING BANK (CIMB Jika Ada)
        </div>
        <Form.Item
          name="accountOwner"
          label={
            <div className="flex items-center">
              <label className="mr-2 font-medium">Nama Pemegang Rekening</label>
              <span className="text-red-500">*</span>
            </div>
          }
          validateStatus={errors.accountOwner ? "error" : ""}
          help={errors.accountOwner ? errors.accountOwner.message : ""}
        >
          <Controller
            name="accountOwner"
            control={control}
            render={({ field }) => (
              <Input
                {...field}
                placeholder="Masukkan nama pemegang rekening anda"
              />
            )}
          />
        </Form.Item>

        <Form.Item
          name="accountNumber"
          required={false}
          label={
            <div className="flex items-center">
              <label className="mr-2 font-medium">Nomor Rekening</label>
              <span className="text-red-500">*</span>
            </div>
          }
          validateStatus={errors.accountNumber ? "error" : ""}
          help={errors.accountNumber ? errors.accountNumber.message : ""}
        >
          <Controller
            name="accountNumber"
            control={control}
            render={({ field }) => (
              <Input
                {...field}
                placeholder="Masukkan nomor rekening anda"
              />
            )}
          />
        </Form.Item>

        <Form.Item
          label={
            <div className="flex items-center">
              <label className="mr-2 font-medium">Nama Bank</label>
              <span className="text-red-500">*</span>
            </div>
          }
          validateStatus={
            errors.bankId ||
            (watch("bankId") === 5 && errors.otherBankName)
              ? "error"
              : ""
          }
          help={errors.bankId ? errors.bankId.message : ""}
        >
          <Controller
            control={control}
            name="bankId"
            render={({ field }) => (
              <>
                <Select
                  placeholder="Pilih nama bank"
                  options={bankNames()}
                  {...field}
                />
              </>
            )}
          />
          {watch("bankId") === 5 && (
            <>
              <Controller
                name="otherBankName"
                control={control}
                render={({ field }) => (
                  <Input
                  className="mt-3"
                    placeholder="Masukkan nama bank lainnya"
                    {...field}
                  />
                )}
              />

              <div>
                <p className="text-red-500">
                  {errors["otherBankName"]?.message}
                </p>
              </div>
            </>
          )}
        </Form.Item>

        <Form.Item
          name="branch"
          required={false}
          label={
            <div className="flex items-center">
              <label className="mr-2 font-medium">Cabang</label>
              <span className="text-red-500">*</span>
            </div>
          }
          validateStatus={errors.branch ? "error" : ""}
          help={errors.branch ? errors.branch.message : ""}
        >
          <Controller
            name="branch"
            control={control}
            render={({ field }) => (
              <Input
                {...field}
                placeholder="Masukkan cabang bank pembukaan rekening anda"
              />
            )}
          />
        </Form.Item>

        <Form.Item wrapperCol={{ offset: 0, span: 16 }}>
          <Button
            className="bg-[#948A54] mr-2"
            type="primary"
            onClick={() => onPrevPage()}
          >
            Kembali
          </Button>
          <Button
            className="bg-[#948A54] mr-2"
            type="primary"
            htmlType="submit"
          >
            Lanjutkan
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};
export default SkillForm;
