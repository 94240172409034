import Image from "next/image";
import AccelistLogo from '../public/logo.png';
import { Steps } from "antd";

export const HeaderForm: React.FC<{
    steps?: number
}> = ({ steps }) => {
    return (
        <div className="flex items-center flex-col w-full">
            <div className="w-[200px] md:w-[250px] lg:w-[300px]">
                <Image
                    src={AccelistLogo}
                    alt="Accelist Logo"
                    style={{
                        width: '100%',
                        height: 'auto',
                    }}
                />
            </div>
            <div className="w-full mb-5">
                {steps === 0 && <div className="font-bold text-xl mb-2 mt-10">Formulir Aplikasi Karyawan</div> }
                {steps !== 0 && steps !== 4 && <div className="font-bold text-xl mb-2 mt-10">Formulir Data Karyawan</div>}
                {steps === 4 && <div className="font-bold text-xl mb-2 mt-10">Pas Foto</div>}
                <Steps
                    style={{color: "#948A54"}}
                    current={steps}
                    items={[
                        {
                            title: '',
                        },
                        {
                            title: '',
                        },
                        {
                            title: '',
                        },
                        {
                            title: '',
                        },
                        {
                            title: '',
                        },
                    ]}
                    responsive={false}
                    className="py-5"
                />
                <hr />
            </div>
        </div>
    );
}
