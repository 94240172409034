import React from 'react';
import { Modal, Button } from 'antd';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck} from "@fortawesome/free-solid-svg-icons";

interface PopUpSuccessProps {
  onClose: () => void;
  children: React.ReactNode;
  onFirstPage: () => void;
  isVisible: boolean;
}

const PopUpSuccess: React.FC<PopUpSuccessProps> = ({children, onFirstPage, isVisible }) => {

  return (
    <Modal
    title={
        <div className="flex flex-col items-center text-center">
        <FontAwesomeIcon 
            icon={faCircleCheck} 
            className="text-[#368c0d] text-7xl mb-2 mt-5" 
        />
          <div className="text-3xl font-bold mt-5">Pengumpulan Berhasil</div>
        </div>
      }
      visible={isVisible}
      onCancel={onFirstPage}
      maskClosable={false}
      footer={
        <div className="flex justify-center mt-10">
        <Button key="ok" onClick={onFirstPage} className="bg-[#948A54] text-white">
          Tutup
        </Button>,
        </div>
      }
    >
      {children}
    </Modal>
  );
};

export default PopUpSuccess;
