import React, { useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import Image from "next/image";
import AccelistLogo from '../public/logo.png';

export default function CaptchaVerificationForm({ onNextPage, reCaptchaSiteKey, reCaptchaSecretKey }) {
  const recaptchaRef = React.createRef();
  const [isRecaptchaVerified, setIsRecaptchaVerified] = useState(false);
  const handleSubmit = (event) => {
    event.preventDefault();
    // Jika reCAPTCHA sudah terverifikasi, lanjutkan ke halaman berikutnya
    if (isRecaptchaVerified) {
      onNextPage();
    }
  };

  const onReCAPTCHAChange = async (captchaCode) => {
    if (!captchaCode) {
      return;
    }
    try {
      const response = await fetch("/api/verify-recaptcha", {
        method: "POST",
        body: JSON.stringify({ captcha: captchaCode, reCaptchaSecretKey: reCaptchaSecretKey }),
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (response.ok) {
        setIsRecaptchaVerified(true);
        onNextPage(); // Memanggil onNextPage saat terverifikasi
      } else {
        setIsRecaptchaVerified(false);
        const error = await response.json();
        throw new Error(error.message);
      }
    } catch (error) {
      // alert(error?.message || "Something went wrong");
      setIsRecaptchaVerified(false);
      recaptchaRef.current.reset();
    }
  };

  return (
    <>
      <div className="flex items-center flex-col w-full">
        <div className="w-[200px] md:w-[250px] lg:w-[300px] mb-10">
          <Image
            src={AccelistLogo}
            alt="Accelist Logo"
            style={{
              width: '100%',
              height: 'auto',
            }}
          />
        </div>
        <div className="text-justify">
          <p className="mb-5">Selamat datang calon karyawan PT. Accelist Lentera Indonesia. Informasi pribadi yang Anda cantumkan melalui formulir ini akan disimpan secara eksklusif berdasarkan persetujuan Anda. Kami hanya mengumpulkan informasi yang diperlukan untuk pertimbangan penerimaan karyawan baru di PT. Accelist Lentera Indonesia.</p>
          <p className="mb-5">Nomor KTP dan informasi pribadi Anda akan dijaga kerahasiaannya dan tidak akan dibagikan kepada pihak lain tanpa izin Anda.</p>
          <p>Setelah formulir dikumpulkan, tim HR PT. Accelist Lentera Indonesia akan meninjau pendaftaran Anda. Jika lolos peninjauan, Anda akan dihubungi untuk langkah-langkah selanjutnya dalam proses rekrutmen.</p>
        </div>
        <form onSubmit={handleSubmit}>
          <ReCAPTCHA
            className="m-auto mt-10"
            ref={recaptchaRef}
            sitekey={reCaptchaSiteKey}
            onChange={onReCAPTCHAChange}
            disabled={isRecaptchaVerified === false}
          />
        </form>
      </div>
    </>
  );
}
