import { Title } from '../components/Title';
import { Page } from '../types/Page';
import ApplyForm, { applyFormDataType } from '@/components/ApplyForm';
import PersonalDataForm, { personalFormDataType } from '@/components/PersonalDataForm';
import MainFamilyForm, { mainFamilyFormDataType } from '@/components/MainFamilyForm';
import WorkHistoryForm, { workHistoryFormDataType } from '@/components/WorkHistoryForm';
import SkillForm, { skillFormDataType } from '@/components/SkillForm';
import BankInfoForm, { bankInfoFormDataType } from '@/components/BankInfoForm';
import PhotoForm, { photoFormDataType } from '@/components/PhotoForm';
import { useEffect, useState } from 'react';
import BiologicalFamilyForm, { biologicalFamilyFormDataType } from '@/components/BiologicalFamilyForm';
import CaptchaVerificationForm from '../components/CaptchaVerificationForm';
import { AppSettings } from '../functions/AppSettings';

const IndexPage: Page<{
    reCaptchaSiteKey: string,
    reCaptchaSecretKey: string  
}> = ({ reCaptchaSiteKey, reCaptchaSecretKey }) => {
    const [step, setStep] = useState(0);
    const [applyForm, setApplyForm] = useState<applyFormDataType>();
    const [personalDataForm, setPersonalDataForm] = useState<personalFormDataType>();
    const [mainFamilyForm, setMainFamilyForm] = useState<mainFamilyFormDataType>();
    const [biologicalFamilyForm, setBiologicalFamilyForm] = useState<biologicalFamilyFormDataType>();
    const [workHistoryForm, setWorkHistoryForm] = useState<workHistoryFormDataType>();
    const [skillForm, setSkillForm] = useState<skillFormDataType>();
    const [bankInfoForm, setBankInfoForm] = useState<bankInfoFormDataType>();
    const [photoForm, setPhotoForm] = useState<photoFormDataType>();

    useEffect(() => {
        const savedStep = sessionStorage.getItem('step');
        const savedApplyForm = sessionStorage.getItem('applyForm');
        const savedPersonalDataForm = sessionStorage.getItem('personalDataForm');
        const savedMainFamilyForm = sessionStorage.getItem('mainFamilyForm');
        const savedBiologicalFamilyForm = sessionStorage.getItem('biologicalFamilyForm');
        const savedWorkHistoryForm = sessionStorage.getItem('workHistoryForm');
        const savedSkillForm = sessionStorage.getItem('skillForm');
        const savedBankInfoForm = sessionStorage.getItem('bankInfoForm');
        const savedPhotoForm = sessionStorage.getItem('photoForm');

        if (savedApplyForm) {
            setApplyForm(JSON.parse(savedApplyForm));
        }
        if (savedPersonalDataForm) {
            setPersonalDataForm(JSON.parse(savedPersonalDataForm));
        }
        if (savedMainFamilyForm) {
            setMainFamilyForm(JSON.parse(savedMainFamilyForm));
        }
        if (savedBiologicalFamilyForm) {
            setBiologicalFamilyForm(JSON.parse(savedBiologicalFamilyForm));
        }
        if (savedWorkHistoryForm) {
            setWorkHistoryForm(JSON.parse(savedWorkHistoryForm));
        }
        if (savedSkillForm) {
            setSkillForm(JSON.parse(savedSkillForm));
        }
        if (savedBankInfoForm) {
            setBankInfoForm(JSON.parse(savedBankInfoForm));
        }
        if (savedPhotoForm) {
            setPhotoForm(JSON.parse(savedPhotoForm));
        }
        if (savedStep !== null) {
            setStep(parseInt(savedStep, 10));
        }
    }, []);

    useEffect(() => {
        if (applyForm) {
            sessionStorage.setItem('applyForm', JSON.stringify(applyForm));
        }
        if (personalDataForm) {
            sessionStorage.setItem('personalDataForm', JSON.stringify(personalDataForm));
        }
        if (mainFamilyForm) {
            sessionStorage.setItem('mainFamilyForm', JSON.stringify(mainFamilyForm));
        }
        if (biologicalFamilyForm) {
            sessionStorage.setItem('biologicalFamilyForm', JSON.stringify(biologicalFamilyForm));
        }
        if (workHistoryForm) {
            sessionStorage.setItem('workHistoryForm', JSON.stringify(workHistoryForm));
        }
        if (skillForm) {
            sessionStorage.setItem('skillForm', JSON.stringify(skillForm));
        }
        if (bankInfoForm) {
            sessionStorage.setItem('bankInfoForm', JSON.stringify(bankInfoForm));
        }
        if (photoForm) {
            sessionStorage.setItem('photoForm', JSON.stringify(photoForm));
        }
    }, [applyForm, personalDataForm, mainFamilyForm, biologicalFamilyForm, workHistoryForm, skillForm, bankInfoForm, photoForm]);

    useEffect(() => {
        if (step === 0) {
            sessionStorage.clear();
            setApplyForm(undefined);
            setPersonalDataForm(undefined);
            setMainFamilyForm(undefined);
            setBiologicalFamilyForm(undefined);
            setWorkHistoryForm(undefined);
            setSkillForm(undefined);
            setBankInfoForm(undefined);
            setPhotoForm(undefined);
            history.pushState(null, "", window.location.href);
            history.back();
            window.onpopstate = () => history.forward();
        }
    }, [step])

    useEffect(() => {
        const handlePopState = () => {
            const newStep = window.location.hash.match(/#\/step-(\d+)/)?.[1];
            if (newStep !== undefined && newStep !== null) {
                setStep(Number(newStep));
            } else {
                setStep(0);
            }
        };

        window.addEventListener('popstate', handlePopState);

        return () => {
            window.removeEventListener('popstate', handlePopState);
        };
    }, [step]);

    const updateStep = (newStep) => {
        window.location.hash = `#/step-${newStep}`;
        setStep(newStep);
        sessionStorage.setItem('step', newStep);
    };

    return (
        <div className="flex flex-col items-center justify-center min-h-screen bg-[#948A5480]">
            <Title>Formulir aplikasi karyawan</Title>
            <div className="flex justify-center items-center flex-grow mt-10 mb-10 mx-5">
                <div className="w-full max-w-[1000px] lg:w-[100%] md:w-[80%] rounded-lg overflow-hidden shadow-lg bg-white p-6 flex flex-col md:flex-row">
                    <div className="w-full md:w-[600px] py-4">
                        {step === 0 && <CaptchaVerificationForm
                            reCaptchaSiteKey={reCaptchaSiteKey}
                            reCaptchaSecretKey={reCaptchaSecretKey}
                            onNextPage={() => { updateStep(step + 1) }} />}
                        {step === 1 && <ApplyForm
                            onSubmit={(data) => { setApplyForm(data); }}
                            applyForm={applyForm}
                            onNextPage={() => updateStep(step + 1)} />}
                        {step === 2 && <PersonalDataForm
                            onSubmit={(data) => { setPersonalDataForm(data); }}
                            personalDataForm={personalDataForm}
                            onNextPage={() => updateStep(step + 1)}
                            onPrevPage={() => updateStep(step - 1)} />}
                        {step === 3 && <MainFamilyForm
                            onSubmit={(data) => { setMainFamilyForm(data) }}
                            mainFamilyForm={mainFamilyForm}
                            onNextPage={() => updateStep(step + 1)}
                            onPrevPage={() => updateStep(step - 1)} />}
                        {step === 4 && <BiologicalFamilyForm
                            onSubmit={(data) => setBiologicalFamilyForm(data)}
                            biologicalFamilyForm={biologicalFamilyForm}
                            onNextPage={() => updateStep(step + 1)}
                            onPrevPage={() => updateStep(step - 1)} />}
                        {step === 5 && <WorkHistoryForm
                            onSubmit={(data) => { setWorkHistoryForm(data) }}
                            workHistoryForm={workHistoryForm}
                            onNextPage={() => updateStep(step + 1)}
                            onPrevPage={() => updateStep(step - 1)} />}
                        {step === 6 && <SkillForm
                            onSubmit={(data) => { setSkillForm(data) }}
                            skillForm={skillForm}
                            onNextPage={() => updateStep(step + 1)}
                            onPrevPage={() => updateStep(step - 1)} />}
                        {step === 7 && <BankInfoForm
                            onSubmit={(data) => { setBankInfoForm(data) }}
                            bankInfoForm={bankInfoForm}
                            onNextPage={() => updateStep(step + 1)}
                            onPrevPage={() => updateStep(step - 1)} />}

                        {step === 8 && <PhotoForm
                            onSubmit={(data) => { setPhotoForm(data) }}
                            applyForm={applyForm}
                            personalDataForm={personalDataForm}
                            mainFamilyForm={mainFamilyForm}
                            workHistoryForm={workHistoryForm}
                            skillForm={skillForm}
                            bankInfoForm={bankInfoForm}
                            biologicalFamilyForm={biologicalFamilyForm}
                            photoForm={photoForm}
                            onNextPage={() => { updateStep(0); window.location.reload(); }}
                            onPrevPage={(data) => { setPhotoForm(data); updateStep(step - 1) }} />}
                    </div>
                </div>
            </div>
        </div>
    );
}

export async function getServerSideProps() {
    return {
        props: {
            reCaptchaSiteKey: AppSettings.current.recaptchaSiteKey,
            reCaptchaSecretKey: AppSettings.current.recaptchaSecretKey
        }
    }
}

export default IndexPage;
