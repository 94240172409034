import { Button, Form, Input } from "antd";
import React, { useEffect, useState } from "react";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm, useFieldArray, Controller } from "react-hook-form";
import { HeaderForm } from "./HeaderForm";

const FormSchema = z.object({
  hubungan: z
    .string({
      required_error: "Hubungan tidak boleh kosong",
    })
    .nonempty({
      message: "Hubungan tidak boleh kosong",
    }),
  namaLengkap: z
    .string()
    .nonempty({
      message: "Nama lengkap tidak boleh kosong",
    })
    .max(255, {
      message: "Nama lengkap harus kurang dari 255 karakter",
    })
    .refine((value) => !/^\s*$/.test(value), {
      message: "Tidak boleh hanya berisi spasi",
    }),
  tempatLahir: z
    .string()
    .nonempty({
      message: "Tempat lahir tidak boleh kosong",
    })
    .max(255, {
      message: "Tempat lahir harus kurang dari 255 karakter",
    })
    .refine((value) => !/^\s*$/.test(value), {
      message: "Tidak boleh hanya berisi spasi",
    }),
  tglLahir: z
    .string({
      required_error: "Tanggal lahir tidak boleh kosong",
    })
    .nonempty({
      message: "Tanggal lahir tidak boleh kosong",
    })
    .refine(
      (value) => {
        if (!value) return true;
        const inputDate = new Date(value);
        const today = new Date();
        return inputDate <= today;
      },
      {
        message: "Tanggal tidak boleh lebih besar dari tanggal hari ini",
      }
    ),
  pekerjaan: z
    .string()
    .nonempty({
      message: "Pekerjaan tidak boleh kosong",
    })
    .max(255, {
      message: "Pekerjaan harus kurang dari 255 karakter",
    })
    .refine((value) => !/^\s*$/.test(value), {
      message: "Tidak boleh hanya berisi spasi",
    }),
  child: z
    .array(
      z.object({
        namaLengkap: z
          .string()
          .nonempty({
            message: "Nama lengkap tidak boleh kosong",
          })
          .max(255, {
            message: "Nama lengkap harus kurang dari 255 karakter",
          })
          .refine((value) => !/^\s*$/.test(value), {
            message: "Tidak boleh hanya berisi spasi",
          }),
        tempatLahir: z
          .string()
          .nonempty({
            message: "Tempat lahir tidak boleh kosong",
          })
          .max(255, {
            message: "Tempat lahir harus kurang dari 255 karakter",
          })
          .refine((value) => !/^\s*$/.test(value), {
            message: "Tidak boleh hanya berisi spasi",
          }),
        tgglLahir: z
          .string({
            required_error: "Tanggal lahir tidak boleh kosong",
          })
          .nonempty({
            message: "Tanggal lahir tidak boleh kosong",
          })
          .refine(
            (value) => {
              if (!value) return true;
              const inputDate = new Date(value);
              const today = new Date();
              return inputDate <= today;
            },
            {
              message: "Tanggal tidak boleh lebih besar dari tanggal hari ini",
            }
          ),
        jenisKelamin: z.string().nonempty({
          message: "Jenis kelamin tidak boleh kosong",
        }),
        pekerjaan: z
          .string()
          .nonempty({
            message: "Pekerjaan tidak boleh kosong",
          })
          .max(255, {
            message: "Pekerjaan harus kurang dari 255 karakter",
          })
          .refine((value) => !/^\s*$/.test(value), {
            message: "Tidak boleh hanya berisi spasi",
          }),
      })
    )
    .min(0),
});

export type mainFamilyFormDataType = z.infer<typeof FormSchema>;

const MainFamilyForm: React.FC<{
  onSubmit: (data: mainFamilyFormDataType) => void;
  mainFamilyForm?: mainFamilyFormDataType;
  onNextPage: () => void;
  onPrevPage: () => void;
}> = ({ onSubmit, mainFamilyForm, onNextPage, onPrevPage }) => {
  const {
    handleSubmit,
    control,
    setValue,
    watch,
    formState: { errors },
  } = useForm<mainFamilyFormDataType>({
    defaultValues: {
      namaLengkap: "",
      tempatLahir: "",
      tglLahir: "",
      pekerjaan: "",
    },
    resolver: zodResolver(FormSchema),
  });
  
  const hubunganValue = watch("hubungan");

  const { fields, append, remove } = useFieldArray({
    control,
    name: "child",
  });

  useEffect(() => {
    if (mainFamilyForm) {
      setValue("hubungan", mainFamilyForm.hubungan);
      setValue("namaLengkap", mainFamilyForm.namaLengkap);
      setValue("tempatLahir", mainFamilyForm.tempatLahir);
      setValue("tglLahir", mainFamilyForm.tglLahir);
      setValue("pekerjaan", mainFamilyForm.pekerjaan);
      setValue("child", mainFamilyForm.child);
    }
  }, [mainFamilyForm, setValue]);

  const [firstVisit, setFirstVisit] = useState(true);

  useEffect(() => {
    if (firstVisit) {
      // Jika ini adalah kunjungan pertama, arahkan ke atas halaman
      window.scrollTo(0, 0);

      // Setel status kunjungan pertama menjadi false
      setFirstVisit(false);
    }
  }, [firstVisit]);

  function onHandleFormSubmit(data: mainFamilyFormDataType) {
    onSubmit(data);
    onNextPage();
  }

  return (
    <>
      <HeaderForm steps={2} />
      <Form layout="vertical" onFinish={handleSubmit(onHandleFormSubmit)}>
        <div className="font-bold text-xl mb-2 mt-5">B. DATA KELUARGA INTI</div>
        <div className="text-gray-500 text-center italic">
          (isi (-) jika status pernikahan lajang atau bercerai)
        </div>

        <Form.Item
          name="hubungan"
          label={
            <div className="flex items-center">
              <label className="mr-2 font-medium">Hubungan</label>
              <span className="text-red-500">*</span>
            </div>
          }
          validateStatus={errors.hubungan ? "error" : ""}
          help={errors.hubungan ? errors.hubungan.message : ""}
        >
          <div className="flex flex-col">
            <Controller
              name="hubungan"
              control={control}
              render={({ field }) => (
                <div className="flex">
                  <label className="flex items-center space-x-2">
                    <input
                      type="radio"
                      {...field}
                      checked={field.value === "4"}
                      onChange={() => {
                        field.onChange("4");
                        setValue("namaLengkap", "");
                        setValue("tempatLahir", "");
                        setValue("tglLahir", "");
                        setValue("pekerjaan", "");
                        setValue("child", []);
                      }}
                      className="appearance-none border-2 border-[#948A54] rounded-full w-4 h-4 checked:bg-[#948A54] checked:border-[#948A54] inline-flex items-center justify-center"
                    />
                    <span>Suami</span>
                  </label>
                  <label className="flex items-center space-x-2 ml-5">
                    <input
                      type="radio"
                      {...field}
                      checked={field.value === "3"}
                      onChange={() => {
                        field.onChange("3");
                        setValue("namaLengkap", "");
                        setValue("tempatLahir", "");
                        setValue("tglLahir", "");
                        setValue("pekerjaan", "");
                        setValue("child", []);
                      }}
                      className="appearance-none border-2 border-[#948A54] rounded-full w-4 h-4 checked:bg-[#948A54] checked:border-[#948A54] inline-flex items-center justify-center"
                    />
                    <span>Istri</span>
                  </label>
                  <label className="flex items-center space-x-2 ml-5">
                    <input
                      type="radio"
                      {...field}
                      checked={field.value === "6"}
                      onChange={() => {
                        field.onChange("6");
                        setValue("namaLengkap", "-");
                        setValue("tempatLahir", "-");
                        setValue("tglLahir", "01-01-0001");
                        setValue("pekerjaan", "-");
                        setValue("child", []);
                      }}
                      className="appearance-none border-2 border-[#948A54] rounded-full w-4 h-4 checked:bg-[#948A54] checked:border-[#948A54] inline-flex items-center justify-center"
                    />
                    <span>-</span>
                  </label>
                </div>
              )}
            />
          </div>
        </Form.Item>

        <Form.Item
          name="namaLengkap"
          label={
            <div className="flex items-center">
              <label className="mr-2 font-medium">Nama Lengkap</label>
              <span className="text-red-500">*</span>
            </div>
          }
          validateStatus={errors.namaLengkap ? "error" : ""}
          help={errors.namaLengkap ? errors.namaLengkap.message : ""}
        >
          <Controller
            name="namaLengkap"
            control={control}
            render={({ field }) => (
              <Input {...field} placeholder="Masukkan nama lengkap" />
            )}
          />
        </Form.Item>

        <Form.Item
          name="tempatLahir"
          label={
            <div className="flex items-center">
              <label className="mr-2 font-medium">Tempat Lahir</label>
              <span className="text-red-500">*</span>
            </div>
          }
          validateStatus={errors.tempatLahir ? "error" : ""}
          help={errors.tempatLahir ? errors.tempatLahir.message : ""}
        >
          <Controller
            name="tempatLahir"
            control={control}
            render={({ field }) => (
              <Input {...field} placeholder="Masukkan tempat lahir" />
            )}
          />
        </Form.Item>

        <Form.Item
          name="tglLahir"
          label={
            <div className="flex items-center">
              <label className="mr-2 font-medium">Tanggal Lahir</label>
              {hubunganValue === "4" || hubunganValue === "3" ? (
                <span className="text-red-500">*</span>
              ) : null}
            </div>
          }
          validateStatus={errors.tglLahir ? "error" : ""}
          help={errors.tglLahir ? errors.tglLahir.message : ""}
        >
          <Controller
            name="tglLahir"
            control={control}
            render={({ field }) => (
              <Input type="date" {...field} placeholder="Pilih tanggal lahir" />
            )}
          />
        </Form.Item>

        <Form.Item
          name="job"
          label={
            <div className="flex items-center">
              <label className="mr-2 font-medium">Pekerjaan</label>
              <span className="text-red-500">*</span>
            </div>
          }
          validateStatus={errors.pekerjaan ? "error" : ""}
          help={errors.pekerjaan ? errors.pekerjaan.message : ""}
        >
          <Controller
            name="pekerjaan"
            control={control}
            render={({ field }) => (
              <Input {...field} placeholder="Masukkan pekerjaan" />
            )}
          />
        </Form.Item>

        <Form.Item>
          {fields.map((item, index) => (
            <div key={item.id} className="mb-5">
              <label className="mr-2 mt-3 font-bold">Anak Ke-{index + 1}</label>

              <Form.Item
                name="namaLengkap"
                label={
                  <div className="flex items-center">
                    <label className="mr-2 font-medium">Nama Lengkap</label>
                    <span className="text-red-500">*</span>
                  </div>
                }
                validateStatus={
                  errors.child?.[index]?.namaLengkap ? "error" : ""
                }
                help={
                  errors.child?.[index]?.namaLengkap
                    ? errors.child[index]?.namaLengkap?.message
                    : ""
                }
              >
                <Controller
                  name={`child.${index}.namaLengkap`}
                  control={control}
                  render={({ field }) => (
                    <Input {...field} placeholder="Masukkan nama lengkap" />
                  )}
                />
              </Form.Item>

              <Form.Item
                name="tempatLahir"
                label={
                  <div className="flex items-center">
                    <label className="mr-2 font-medium">Tempat Lahir</label>
                    <span className="text-red-500">*</span>
                  </div>
                }
                validateStatus={
                  errors.child?.[index]?.tempatLahir ? "error" : ""
                }
                help={
                  errors.child?.[index]?.tempatLahir
                    ? errors.child[index]?.tempatLahir?.message
                    : ""
                }
              >
                <Controller
                  name={`child.${index}.tempatLahir`}
                  control={control}
                  render={({ field }) => (
                    <Input {...field} placeholder="Masukkan tempat lahir" />
                  )}
                />
              </Form.Item>

              <Form.Item
                name="tglLahir"
                label={
                  <div className="flex items-center">
                    <label className="mr-2 font-medium">Tanggal Lahir</label>
                    <span className="text-red-500">*</span>
                  </div>
                }
                validateStatus={errors.child?.[index]?.tgglLahir ? "error" : ""}
                help={
                  errors.child?.[index]?.tgglLahir
                    ? errors.child[index]?.tgglLahir?.message
                    : ""
                }
              >
                <Controller
                  name={`child.${index}.tgglLahir`}
                  control={control}
                  render={({ field }) => (
                    <Input
                      type="date"
                      {...field}
                      placeholder="Pilih tanggal lahir"
                    />
                  )}
                />
              </Form.Item>

              <Form.Item
                name="jenisKelamin"
                label={
                  <div className="flex items-center">
                    <label className="mr-2 font-medium">Jenis Kelamin</label>
                    <span className="text-red-500">*</span>
                  </div>
                }
                validateStatus={
                  errors.child?.[index]?.jenisKelamin ? "error" : ""
                }
                help={
                  errors.child?.[index]?.jenisKelamin
                    ? errors.child[index]?.jenisKelamin?.message
                    : ""
                }
              >
                <Controller
                  name={`child.${index}.jenisKelamin`}
                  control={control}
                  render={({ field }) => (
                    <div className="flex">
                      <label className="flex items-center space-x-2">
                        <input
                          type="radio"
                          {...field}
                          checked={field.value === "M"}
                          onChange={() => field.onChange("M")}
                          className="appearance-none border-2 border-[#948A54] rounded-full w-4 h-4 checked:bg-[#948A54] checked:border-[#948A54] inline-flex items-center justify-center"
                        />
                        <span>Pria</span>
                      </label>
                      <label className="flex items-center space-x-2 ml-5">
                        <input
                          type="radio"
                          {...field}
                          checked={field.value === "F"}
                          onChange={() => field.onChange("F")}
                          className="appearance-none border-2 border-[#948A54] rounded-full w-4 h-4 checked:bg-[#948A54] checked:border-[#948A54] inline-flex items-center justify-center"
                        />
                        <span>Wanita</span>
                      </label>
                    </div>
                  )}
                />
              </Form.Item>

              <Form.Item
                name="pekerjaan"
                label={
                  <div className="flex items-center">
                    <label className="mr-2 font-medium">Pekerjaan</label>
                    <span className="text-red-500">*</span>
                  </div>
                }
                validateStatus={errors.child?.[index]?.pekerjaan ? "error" : ""}
                help={
                  errors.child?.[index]?.pekerjaan
                    ? errors.child[index]?.pekerjaan?.message
                    : ""
                }
              >
                <Controller
                  name={`child.${index}.pekerjaan`}
                  control={control}
                  render={({ field }) => (
                    <Input {...field} placeholder="Masukkan pekerjaan" />
                  )}
                />
              </Form.Item>
            </div>
          ))}

          <div className="flex">
            {fields.length < 5 && (
              <button
                className="bg-[#948A54] text-white border rounded-md mt-2 hover:bg-blue-500 mr-3"
                type="button"
                onClick={() =>
                  append({
                    namaLengkap: "",
                    tempatLahir: "",
                    tgglLahir: "",
                    jenisKelamin: "",
                    pekerjaan: "",
                  })
                }
              >
                <div className="px-4 m-1"> Tambah Anak </div>
              </button>
            )}
            {fields.length >= 1 && (
              <button
                type="button"
                disabled={fields.length === 0}
                className="text-white bg-[#EF3E55] border rounded-md mt-2"
                onClick={() => {
                  if (fields.length >= 1) {
                    remove(fields.length - 1);
                  }
                }}
              >
                <div className="px-4 m-1"> Hapus Anak </div>
              </button>
            )}
          </div>
        </Form.Item>

        <Form.Item wrapperCol={{ offset: 0, span: 16 }}>
          <Button
            className="bg-[#948A54] mr-2"
            type="primary"
            onClick={() => onPrevPage()}
          >
            Kembali
          </Button>
          <Button
            className="bg-[#948A54] mr-2"
            type="primary"
            htmlType="submit"
          >
            Lanjutkan
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};
export default MainFamilyForm;
